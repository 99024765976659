import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { SortingRule } from "react-table";
import { resolveSorting } from "shared/helpers";
import { IAlertingConfig } from "types/placement";
import { v4 as uuidv4 } from "uuid";

type TPlacementParams = {
  machineIds?: number[];
  page?: number;
  ps?: number;
  orderBy?: SortingRule<number>[];
  id?: number[];
};

export async function getPlacements({
  machineIds,
  orderBy,
  ...params
}: TPlacementParams) {
  const url = endpoints.placements.default;
  const urlType = endpoints.placementTypes.default;
  let ids = "";

  try {
    if (machineIds) {
      for (let i of machineIds.sort()) {
        ids += `&machine=${i}`;
      }
    }

    const { data }: any = await axiosNeuron.get(
      `${url}?${ids}&order_by=${resolveSorting("id", orderBy)}`,
      {
        params: { ...params },
      }
    );
    if (data.results) {
      const types: string[] = [];
      for (let i of data.results) {
        if (!types.includes(`&id=${i.type}`)) {
          types.push(`&id=${i.type}`);
        }
      }
      let typeIds = types.join("");
      const { data: finalData } = await axiosNeuron.get(
        `${urlType}?${typeIds}`
      );
      for (let i of data.results) {
        for (let j of finalData.results) {
          if (i.type === j.id) {
            i["name"] = j.name;
          }
        }
      }
      return {
        ...data,
        results: data.results.map(
          (placement: {
            id: number;
            name: string;
            machine: number;
            type: number;
            channel: number;
            panel_id: number | null;
            qr_code: string;
            gain: number;
            custom_name: number;
            is_enabled: boolean;
            running_threshold: number | null;
            sensor_cable_length: number | null;
            sensor_type: number | null;
            iso_group: number | null;
            rpm: number | null;
            is_rpm_automatically_detected: boolean;
            band_alerting: string;
            recording_plan: string | null;
            suppress_notifications_until: string | null;
            envelope_sad: boolean;
            audio_chunk_type: string;
            is_used_for_machine_running_prediction: boolean;
            alerting_config: IAlertingConfig | null;
          }) => ({
            id: placement.id,
            name: placement.name,
            machine: placement.machine,
            type: placement.type,
            channel: placement.channel,
            panelId: placement.panel_id,
            qrCode: placement.qr_code,
            gain: placement.gain,
            customName: placement.custom_name,
            is_enabled: placement.is_enabled,
            running_threshold: placement.running_threshold,
            sensor_cable_length: placement.sensor_cable_length,
            sensor_type: placement.sensor_type,
            iso_group: placement.iso_group,
            rpm: placement.rpm,
            is_rpm_automatically_detected:
              placement.is_rpm_automatically_detected,
            band_alerting: placement.band_alerting,
            recording_plan: placement.recording_plan,
            suppress_notifications_until:
              placement.suppress_notifications_until,
            envelope_sad: placement.envelope_sad,
            audio_chunk_type: placement.audio_chunk_type,
            is_used_for_machine_running_prediction:
              placement.is_used_for_machine_running_prediction,
            alerting_config: placement.alerting_config
              ? {
                  ...placement.alerting_config,
                  rules: placement?.alerting_config?.rules?.length
                    ? placement.alerting_config.rules.map((rule) => ({
                        ...rule,
                        interval:
                          rule.interval === undefined
                            ? 0
                            : Math.round(Number(rule.interval) / 60),
                        min_count:
                          rule.min_count === undefined ? 0 : rule.min_count,
                        id: uuidv4(),
                      }))
                    : [],
                }
              : null,
          })
        ),
      };
    }
  } catch {
    return;
  }
}

import wasmModule from "./wasmwvpk";

export class WasmWavpack {
  constructor(wasmModule) {
    this.module = wasmModule;
  }

  decode_wavpack(inputInt8Array, maxSeconds) {
    const Module = this.module;

    const decodeWavPack = Module.cwrap("decode_wavpack", "number", [
      "number",
      "number",
      "number",
    ]);
    const freeDecodeResult = Module.cwrap("free_decode_result", "void", [
      "number",
    ]);

    const dataPtr = Module._malloc(inputInt8Array.length);
    Module.HEAPU8.set(inputInt8Array, dataPtr);

    const resultPtr = decodeWavPack(
      dataPtr,
      inputInt8Array.length,
      maxSeconds ? maxSeconds : 0
    );
    Module._free(dataPtr);

    if (!resultPtr) {
      throw new Error("Decoding failed!");
    }

    // Read fields explicitly using DataView instead of getValue()
    const heapU8 = Module.HEAPU8.buffer;
    const resultView = new DataView(heapU8, resultPtr, 16); // 4 fields × 4 bytes each

    const samplesPtr = resultView.getUint32(0, true);
    const sampleRate = resultView.getUint32(4, true);
    const numSamples = resultView.getUint32(8, true);
    const numChannels = resultView.getUint32(12, true);

    const totalSamples = numSamples * numChannels;

    const samples = new Float32Array(
      Module.HEAPF32.buffer,
      samplesPtr,
      totalSamples
    );

    const channelData = new Float32Array(samples);

    freeDecodeResult(resultPtr);

    return { channelData, sampleRate };
  }
}

var wasmWavpackInstance;

(async () => {
  wasmWavpackInstance = new WasmWavpack(await wasmModule());
})();

export const decode_wavpack = (bytesData, maxSeconds) =>
  wasmWavpackInstance.decode_wavpack(bytesData, maxSeconds);

import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function removeMachineManual(id: number) {
  const url = endpoints.machines.detailManualAttachements(id);
  try {
    const response = await axiosNeuron.delete(url);
    return response;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}

import { combineReducers } from "redux";
import pageReducer from "./page/reducer";
import tokenReducer from "./token/reducer";
import forgottenPasswordReducer from "./forgottenPassword/reducer";
import authReducer from "./auth/reducer";
import machineFilterReducer from "./machineFilter/reducer";
import playerReducer from "./player/reducer";
import serverErrorReducer from "./serverError/reducer";
import createUserReducer from "./createUser/reducer";
import usersReducer from "./users/reducer";
import currenciesReducer from "./currencies/reducer";
import createDeviceDetailReducer from "./deviceDetail/reducer";
import createChannelsListReducer from "./deviceDetail/channelsList/reducer";
import createDevicesListReducer from "./devicesList/reducer";
import eventsReducer from "./events/reducer";
import userDetailFilterReducer from "./userDetailFilter/reducer";
import machinesListReducer from "./machinesList/reducer";
import machineDetailReducer from "./machineDetail/reducer";
import notifierReducer from "./notifier/reducer";
import companiesListReducer from "./companiesList/reducer";
import companyDetailReducer from "./companyDetail/reducer";
import addUserModalReducer from "./addUserModal/reducer";
import placementsListReducer from "./machineDetail/placements/reducer";
import audioUploaderReducer from "./machineDetail/audioUploader/reducer";
import analysisTimeManagerReducer from "./machineDetail/analysisTimeManager/reducer";
import analysisSoundsReducer from "./machineDetail/analysisSounds/reducer";
import eventsExporterReducer from "./machineDetail/eventsExporter/reducer";
import oneClickReducer from "./machineDetail/oneClickTraining/reducer";
import machineManualReducer from "./machineDetail/machineManuals/reducer";
import facilities from "./projectDetail/facilities/reducer";
import facility from "./facilityDetail/facility/reducer";
import users from "./projectDetail/users/reducer";
import reports from "./projectDetail/reports/reducer";
import project from "./projectDetail/project/reducer";
import temporaryAccessToken from "./projectDetail/temporaryToken/reducer";
import machines from "./projectDetail/machines/reducer";
import companiesAndProjectsReducer from "./companiesAndProjects/reducer";
import activateDeviceReducer from "./activateDevice/reducers";
import langsReducer from "./langs/reducers";
import modalsReducer from "./modals/reducers";
import layoutReducer from "./layout/reducers";
import soundsReducer from "./machineDetail/sounds/reducer";
import isoGroupReducers from "./iso_group/reducers";
import maintenanceTypeReducer from "./maintenanceType/reducers";
import eventStatusesReducer from "./eventStatuses/reducers";
import { reducer as failureTypes } from "./failureTypes/slice";
import { reducer as eventModal } from "./eventModal/slice";
import attachmentsReducer from "./attachments/reducer";
import modelsReducer from "./models/reducer";
import faultsReducer from "./faults/reducer";
import accessTokensReducer from "./accessTokens/reducer";
import eventSoundsReducer from "./eventSounds/reducer";
import ServiceManagerReducer from "./servivceManager/reducer";
import customModelReducer from "./customModels/reducer";
import CustomModelCategoryReducer from "./customModelCategory/reducer";
import PlacementImagesReducer from "./machineDetail/placementImage/reducer";

export const appReducer = {
  langs: langsReducer,
  page: pageReducer,
  token: tokenReducer,
  forgottenPassword: forgottenPasswordReducer,
  auth: authReducer,
  machineFilter: machineFilterReducer,
  player: playerReducer,
  serverError: serverErrorReducer,
  createUser: createUserReducer,
  eventModal,
  users: usersReducer,
  userDetailFilter: userDetailFilterReducer,
  deviceDetail: combineReducers({
    detail: createDeviceDetailReducer,
    channelsList: createChannelsListReducer,
  }),
  devicesList: createDevicesListReducer,
  events: eventsReducer,
  machinesList: machinesListReducer,
  machineDetail: combineReducers({
    machine: machineDetailReducer,
    placements: placementsListReducer,
    audioUploader: audioUploaderReducer,
    eventsExporter: eventsExporterReducer,
    oneClickTraining: oneClickReducer,
    sounds: soundsReducer,
    analysisTimeManager: analysisTimeManagerReducer,
    analysisSounds: analysisSoundsReducer,
    placementImages: PlacementImagesReducer,
    machineManuals: machineManualReducer,
  }),
  companiesList: companiesListReducer,
  notifier: notifierReducer,
  companyDetail: companyDetailReducer,
  addUserModal: addUserModalReducer,
  projectDetail: combineReducers({
    users,
    project,
    facilities,
    reports,
    temporaryAccessToken,
    machines,
  }),
  companiesAndProjects: companiesAndProjectsReducer,
  facilityDetail: combineReducers({
    facility,
  }),
  activateDevice: activateDeviceReducer,
  modals: modalsReducer,
  layout: layoutReducer,
  isoGroup: isoGroupReducers,
  maintenanceTypes: maintenanceTypeReducer,
  eventStatuses: eventStatusesReducer,
  failureTypes,
  attachments: attachmentsReducer,
  models: modelsReducer,
  currencies: currenciesReducer,
  faults: faultsReducer,
  accessTokens: accessTokensReducer,
  eventSounds: eventSoundsReducer,
  serviceManager: ServiceManagerReducer,
  customModels: customModelReducer,
  customModelCategory: CustomModelCategoryReducer,
};

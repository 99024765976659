import "./load.css";
const Loading = ({ loading }: { loading: string }) => {
  return (
    <div className="loading">
      <div className="loading-text">
        {loading.split("").map((load) => {
          return <span className="loading-text-words">{load}</span>;
        })}
      </div>
    </div>
  );
};

export default Loading;

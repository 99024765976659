import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface IEventParams {
  id: number;
}

export default async function getAIRecommendation({ id }: IEventParams) {
  const url = endpoints.events.getAIRecommendation(id);

  const fetchUntilComplete = async (callback: (data: any) => void) => {
    while (true) {
      try {
        const { data } = await axiosNeuron.get(url);

        callback(data);

        if (data.status !== "incomplete") {
          callback(data);
          return data;
        }

        await new Promise((res) => setTimeout(res, 2000));
      } catch (error) {
        await new Promise((res) => setTimeout(res, 2000));
      }
    }
  };

  return new Promise((resolve, reject) => {
    fetchUntilComplete((data) => {
      try {
        const formatChatData = (
          queries: string[],
          recommendations: string[]
        ) => {
          const maxLength = Math.max(queries.length, recommendations.length);

          return Array.from({ length: maxLength }, (_, index) => ({
            question: queries[index] || "",
            answer: recommendations[index] || "",
          }));
        };

        const formattedData = {
          ...data,
          results: formatChatData(data.queries, data.recommendations),
        };

        resolve(formattedData);
      } catch (error) {
        reject(error);
      }
    });
  });
}

import IMuiTheme from "types/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: IMuiTheme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(3),
  },
  form: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",

    "& pre": {
      whiteSpace: "pre",
    },
    "& code": {
      display: "block",
      overflowX: "auto",
    },
  },
  list: {
    "& *": {
      margin: 0,
    },

    "& ol": {
      whiteSpace: "normal",
    },
    "& ol > li": {
      whiteSpace: "normal",
    },
    "& ul": {
      whiteSpace: "normal",
    },
    "& ul > li": {
      whiteSpace: "normal",
    },
  },
  label: {
    color: theme.custom.palette.modelInfo.grey,
    fontSize: 14,
    lineHeight: 1,
  },
}));

import { memo } from "react";
import { TableHead, TableRow, SortDirection, TableCell } from "@mui/material";
import { useStyles } from "./styles";
import HeaderLabel from "components/table/header/label";
import { IHeaderCells } from "store/eventSounds/types";
import { TFunction } from "i18next";

type ITableHeadProps = {
  handlePageSort: (event?: any) => void;
  headerCells: IHeaderCells[];
  tt: TFunction;
};

const DatasetTableHead = memo(
  ({ handlePageSort, headerCells, tt }: ITableHeadProps) => {
    const classes = useStyles();

    const tCellContent = (
      name: string,
      sortDirection: "" | "desc" | "asc" | undefined
    ) => {
      const renderName = tt(name);
      return (
        <HeaderLabel
          sorted={sortDirection}
          alignCenter={name === "numberOfSample"}
          onClick={handlePageSort}
          height="unset"
        >
          <div> {renderName}</div>
        </HeaderLabel>
      );
    };

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {headerCells.map((cell: any) => (
            <TableCell
              key={String(cell)}
              className={`${classes.smallPadding} ${classes.tdTableHead}`}
              id={cell.id}
              align={
                cell.displayName === "numberOfSample" ? "center" : "inherit"
              }
              sortDirection={cell.sortDirection as SortDirection | undefined}
            >
              {cell.displayName
                ? tCellContent(cell.displayName, cell.sortDirection)
                : null}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
);

export default DatasetTableHead;

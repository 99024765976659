import React, { memo } from "react";
import ScreenHeading from "screens/sharedParts/screenHeading";
import { Modal } from "components/modal";
import Grid from "@mui/material/Grid";
import Button from "components/button";
import { useStyles } from "components/events/styles";
import { useTranslation } from "react-i18next";
import Spinner from "components/spinner";
import Centered from "components/centered";
import FormModalFooter from "components/form/parts/formModalFooter";
import Box from "@mui/material/Box";
import Heading1 from "components/typography/heading/heading1";
import CallToAction from "components/layout/callToAction";
import { Tooltip } from "@mui/material";

interface IAddEventModal {
  open: boolean;
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  callToAction?: () => void;
  callToActionTitle?: string;
  children: JSX.Element;
  title?: string;
  cta: string;
  submitDisabled: boolean;
  mandatory?: boolean;
  loading?: boolean;
  onSubmit: () => void;
  className?: string;
  toolTip?: string;
  width?: number | string;
  border?: boolean;
}
export const ModalWrap = memo(
  ({
    children,
    open,
    title,
    loading,
    cta,
    mandatory,
    submitDisabled,
    onCancel,
    onSubmit,
    callToAction,
    callToActionTitle,
    className,
    toolTip,
    width,
    border,
  }: IAddEventModal) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
      <Modal onCancel={onCancel} className={className} open={open}>
        {loading ? (
          <Centered>
            <Spinner />
          </Centered>
        ) : (
          <Grid
            container
            justifyContent="space-between"
            direction="column"
            className={classes.fullHeight}
            wrap="nowrap"
          >
            {title ? (
              <Grid
                container
                className={classes.root}
                direction="column"
                wrap="nowrap"
              >
                <Grid item xs={12}>
                  <ScreenHeading>
                    {callToAction && callToActionTitle && (
                      <CallToAction onClick={callToAction}>
                        {t(callToActionTitle)}
                      </CallToAction>
                    )}

                    <Heading1 id="testMachineDetailHeading">{title}</Heading1>
                  </ScreenHeading>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            {children}
            <Grid
              container
              justifyContent="space-between"
              direction="column"
              className={classes.footer}
              borderTop={border ? "5px solid #9E9E9E" : "unset"}
            >
              <Grid item>
                {mandatory && (
                  <Box className={classes.mandatory}>
                    <FormModalFooter>{t("mandatory")}</FormModalFooter>
                  </Box>
                )}
              </Grid>
              <Grid
                item
                width={width ? width : "initial"}
                position={width ? "relative" : "unset"}
              >
                <Grid
                  width={width ? "50%" : "100%"}
                  container
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Button onClick={onCancel} data-cy="cancle">
                      {t("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    {toolTip ? (
                      <Tooltip title={<span>{toolTip}</span>}>
                        <span>
                          <Button
                            disabled={submitDisabled}
                            onClick={onSubmit}
                            id="testModalSubmit"
                            data-cy="cta"
                          >
                            {cta}
                          </Button>
                        </span>
                      </Tooltip>
                    ) : (
                      <Button
                        disabled={submitDisabled}
                        onClick={onSubmit}
                        id="testModalSubmit"
                        data-cy="cta"
                      >
                        {cta}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                {width ? (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      bottom: "1px",
                      left: "50%",
                      width: "1.5px",
                      backgroundColor: "#9f9f9f",
                    }}
                  ></Box>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Modal>
    );
  }
);

import type { IEventList, IEventDetail } from "types/event";
import type {
  TEventPlacementFailure,
  TEventPlacementFailureExtended,
} from "types/failureTypes";
import type { IFailure } from "./selector";
import type { TCommentWithMappedUser } from "types/event";

export enum EModalTypes {
  init = "init",
  finish = "finish",
  create = "create",
  edit = "edit",
}

export enum ELabelDateType {
  start = "start",
  end = "end",
}

export enum EDateMessageType {
  start = "startMessage",
  end = "endMessage",
}

export type LabelModalState = {
  id: number | null;
  subcategory: number;
  description: string;
};

export type LabelGroupModalState = {
  groupId: string;
  [ELabelDateType.start]: string | null;
  [ELabelDateType.end]: string | null;
  subcategory: number | undefined;
  description: string | undefined;
  labels: LabelModalState[];
  toRemoveLabels: number[];
  eventId: number | null;
  toDelete?: boolean;
  placements: number[];
  toRemovePlacements: number[];
};

export type TEventPlacementFailureSelect = Omit<
  TEventPlacementFailure,
  "event" | "failure"
>;

export interface IEventModalState {
  errors: {
    [key: string]: string | null;
  };
  start?: string;
  end?: string;
  startMessage: string;
  endMessage: string;
  modalType: EModalTypes;
  eventType: string | null;
  eventId: number | null;
  labelGroups: LabelGroupModalState[];
  initialLabels: LabelGroupModalState[];
  eventData?: IEventList[];
  lastEventBeforeStart?: IEventDetail | null;
  firstEventAfterEnd?: IEventDetail | null;
  eventBetweenStartAndEnd?: IEventDetail | null;
  failures: TEventPlacementFailureExtended[];
  newFailures: IFailure[];
  maintenanceType: number | null;
  status: string | null;
  statusDefault: string | null;
  isCritical: boolean | null;
  isCriticalDefault: boolean | null;
  presumedCauses: string | null;
  recommendedAction: string | null;
  anomalyTrend: string | null;
  diagDescription: string | null;
  relatedPairEvent: number | null;
  comments?: TCommentWithMappedUser[];
  eventDetail?: IEventDetail;
  loading: boolean;
  validationLoading: boolean;
  isHidden: boolean;
  estimatedPreventedDamageCurrency?: string;
  estimatedPreventedDamageAmount?: number | null;
  aiRecommendation?: any | null;
  aiRecommendationLoading?: boolean;
  aiRecommendationParams?: any | null;
  silentFetch?: boolean;
  isMessageInUse?: boolean;
}

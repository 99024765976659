import { Column } from "react-table";
import { TFunction } from "i18next";

import { useSelector } from "react-redux";
import { AppState } from "store";
import { memo } from "react";
import { makeStyles } from "@mui/styles";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import GetAppIcon from "@mui/icons-material/GetApp";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Tag from "components/tag";
import Heading4 from "components/typography/heading/heading4";
import { dateToString } from "shared/helpers";
import IMuiTheme from "types/styles";

const useStyles = makeStyles((theme: IMuiTheme) => ({
  button: {
    color: theme.custom.palette.primary[100],
    cursor: "pointer",
    display: "block",
    "&:hover": {
      color: theme.custom.palette.data,
    },
  },
  buttonContainer: {
    backgroundColor: theme.custom.palette.border,
    "&:hover": {
      backgroundColor: "#d5d5d5",
    },
  },
  ellipsisBox: {
    display: "table",
    tableLayout: "fixed",
    width: "100%",
  },
  nowrapBox: {
    display: "table",
    width: "100%",
    marginTop: 5,
    marginBottom: -10,
  },
  ellipsis: {
    display: "table-cell",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  nowrap: {
    whiteSpace: "nowrap",
    display: "table-cell",
  },
}));

export const mimeTypeToExtension: { [key: string]: string } = {
  "application/pdf": "PDF",
};

export const Icon = memo(() => (
  <Box ml={2.5}>
    <DescriptionOutlinedIcon />
  </Box>
));

export const Type = memo(
  ({
    cell: {
      value: { mime_type },
    },
  }: any) => {
    const type = mimeTypeToExtension[mime_type];
    return type ? (
      <Box mr={2.5}>
        <Tag color="primary">{type}</Tag>
      </Box>
    ) : null;
  }
);

export const Name = memo(
  ({
    cell: {
      value: { attachment },
    },
  }: any) => {
    const classes = useStyles();
    return (
      <Box className={classes.ellipsisBox}>
        <Heading4
          className={classes.ellipsis}
          title={attachment.split("/").pop()}
        >
          {attachment.split("/").pop()}
        </Heading4>
      </Box>
    );
  }
);

export const UploadInfo = memo(
  ({
    cell: {
      value: { user, created_at },
    },
  }: any) => {
    const classes = useStyles();
    return (
      <Box>
        <Box className={classes.nowrapBox}>
          <Heading4 className={classes.nowrap}>
            {dateToString(new Date(created_at))}
          </Heading4>
        </Box>
        <Box className={classes.ellipsisBox}>
          <Heading4 className={classes.ellipsis}>{user}</Heading4>
        </Box>
      </Box>
    );
  }
);

export const Download = memo(
  ({
    cell: {
      value: { link },
    },
  }: any) => {
    const classes = useStyles();
    return (
      <Box>
        <Link href={link} target="_blank">
          <GetAppIcon className={classes.button} />
        </Link>
      </Box>
    );
  }
);

export const Delete = memo(
  ({
    cell: {
      value: { handleDelete },
    },
  }: any) => {
    const user = useSelector((state: AppState) => state.auth.user);
    const isReadOnly = user.role === "customer";

    const classes = useStyles();
    return handleDelete ? (
      <Box mr={2.5} style={isReadOnly ? { cursor: "not-allowed" } : {}}>
        <Button
          disabled={isReadOnly}
          onClick={handleDelete}
          className={classes.buttonContainer}
          variant="contained"
        >
          <DeleteIcon className={classes.button} />
        </Button>
      </Box>
    ) : null;
  }
);

export const createColumns = (
  t: TFunction,
  handleDelete: any
): Column<any>[] => {
  const upMdColumns: Column<any>[] = [
    {
      id: "icon",
      Header: "",
      disableSortBy: true,
      Cell: Icon as any,
      custom: {
        hidePriority: 0,
        width: 64,
      },
    },
    {
      id: "type",
      Header: "",
      disableSortBy: true,
      accessor: ({ mime_type }) => ({ mime_type }),
      Cell: Type as any,
      custom: {
        hidePriority: 0,
        width: 64,
      },
    },
    {
      id: "name",
      Header: t("machine.event.attachment.name").toString(),
      disableSortBy: true,
      accessor: ({ attachment }) => ({ attachment }),
      Cell: Name as any,
      custom: {
        hidePriority: 0,
      },
    },

    {
      id: "uploadInfo",
      Header: `${t("machine.event.attachment.dateOfUpload").toString()} / ${t(
        "machine.event.attachment.uploadedBy"
      ).toString()}`,
      disableSortBy: true,
      accessor: ({ user, created_at }) => ({ user, created_at }),
      Cell: UploadInfo as any,
      custom: {
        hidePriority: 0,
        width: "15%",
      },
    },
    {
      id: "download",
      Header: "",
      disableSortBy: true,
      accessor: ({ link }) => ({ link }),
      Cell: Download as any,
      custom: {
        hidePriority: 0,
        width: 48,
      },
    },
    {
      id: "delete",
      Header: "",
      disableSortBy: true,
      accessor: ({ id }) => ({
        handleDelete: () => handleDelete(id),
      }),
      Cell: Delete as any,
      custom: {
        hidePriority: 0,
        alignRight: true,
        width: 64,
      },
    },
  ];

  return upMdColumns;
};

import { IMachineManual } from "types/machine";

export enum EActions {
  fetchMachineManualRequest = "fetchMachineManualRequest",
  fetchMachineManualSuccess = "fetchMachineManualSuccess",
  fetchMachineManualFail = "fetchMachineManualFail",
  setReset = "setReset",
  setAttachmentParams = "setAttachmentParams",
}

export interface IMachineManualState {
  manualsAttachment: IMachineManual | null;
  isFetching: boolean;
  error: Error | null;
  attachmentParams: any | null;
  showMoreClickedTimesRef: React.RefObject<number>;
}

export interface IMachineManualAction {
  type: EActions;
  payload: IMachineManualState;
}

export type TReducer = {
  [key: string]: (p: IMachineManualAction, s: IMachineManualState) => void;
};

import { AppState } from "store";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/button";
import { useDispatch } from "react-redux";
import {
  deleteMachineAttachment,
  fetchMachineManuals,
} from "store/machineDetail/machineManuals/actions";
import Spinner from "components/spinner";
import { useMediaQuery, useTheme } from "@mui/material";
import Divider from "components/typography/heading/divider";
import UploadManualAttachment from "./uploadManuals";
import { createTinyColumns } from "./tinyColumns";
import { createColumns } from "./columns";
import Table from "components/table";
import Row from "components/table/row";
import Header from "components/table/header";
import ConfirmPopup from "components/confirmPopup";
import { IMachineManual } from "types/machine";

const pageSize = 10;

const MachineManuals = ({ machine }: { machine: number }) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  const {
    manualsAttachment,
    isFetching,
    showMoreClickedTimesRef,
    attachmentParams: { page },
  } = useSelector((state: AppState) => state.machineDetail.machineManuals);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const openModal = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (showMoreClickedTimesRef.current === null) {
      showMoreClickedTimesRef.current = 0;
    }
  }, [showMoreClickedTimesRef]);

  useEffect(() => {
    dispatch(
      fetchMachineManuals({
        id: machine,
        ps: pageSize,
        page,
      })
    );
  }, [dispatch, machine]);

  const handleDelete = useCallback((id: number) => {
    setDeleteId(id);
  }, []);

  const tinyColumnsMemoized = useMemo(
    () => createTinyColumns(t, handleDelete),
    [t]
  );
  const columnsMemoized = useMemo(() => createColumns(t, handleDelete), [t]);
  const columns = upMd ? columnsMemoized : tinyColumnsMemoized;

  const hideHeader =
    columns === tinyColumnsMemoized ||
    !(manualsAttachment && manualsAttachment.results.length);

  const initialState = useMemo(() => {
    return {
      pageIndex: page,
      pageSize,
      pageCount: manualsAttachment
        ? Math.ceil(manualsAttachment.count / pageSize)
        : 0,
    };
  }, [manualsAttachment, pageSize, page]);

  const handleConfirm = () => {
    if (deleteId) {
      dispatch(deleteMachineAttachment(deleteId!));
      setDeleteId(null);
    }
  };

  const onPageChange = (pageIndex: number) => {
    dispatch(
      fetchMachineManuals({
        id: machine,
        ps: pageSize,
        page: pageIndex,
      })
    );
  };

  return (
    <>
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <Divider line>{t("machine.detail.manualAttachment.header")}</Divider>
          <Table<IMachineManual>
            isLoading={false}
            data={
              manualsAttachment && manualsAttachment?.results?.length
                ? manualsAttachment?.results
                : []
            }
            rowsCount={
              manualsAttachment && manualsAttachment.results.length
                ? manualsAttachment.count
                : 0
            }
            columns={columns as any}
            RowComponent={Row}
            HeaderComponent={Header}
            HeaderComponentProps={{ hide: hideHeader }}
            hidePagination={isFetching}
            onPageChange={onPageChange}
            showMoreClickedTimesRef={showMoreClickedTimesRef}
            initialState={initialState}
            hideNoData={manualsAttachment && manualsAttachment.results.length}
          />
          <Button
            color="primary"
            onClick={openModal}
            id="addMachineManual"
            size="large"
          >
            {t("machine.detail.addAttachment")}
          </Button>

          {open ? (
            <UploadManualAttachment
              open={open}
              setOpen={setOpen}
              machine={machine}
            />
          ) : (
            <></>
          )}
        </>
      )}
      {deleteId && (
        <ConfirmPopup
          onConfirm={handleConfirm}
          title={t("machine.detail.manualAttachment.delete.confirm.title")}
          text={t("machine.detail.manualAttachment.delete.confirm.text")}
          confirmText={t(
            "machine.detail.manualAttachment.delete.confirm.button"
          )}
          noControl={true}
          onCancel={() => setDeleteId(null)}
        />
      )}
    </>
  );
};

export default MachineManuals;

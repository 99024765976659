import { useCallback, useEffect, useState } from "react";
import { TableContainer, Table } from "@mui/material";
import Spinner from "components/spinner";
import useData from "dataHooks/eventSounds/datasets";
import { IDataset } from "types/eventSounds";
import DatasetTableHead from "../components/table/tableHead";
import DatasetTableBody from "../components/table/tableBody";
import { useStyles } from "../components/table/styles";
import Footer from "../components/footer";
import SaveDatasetModal from "./saveWindow";
import Filter from "./components/filters";
import VolumeSlider from "../components/volumeSlider";

import actions from "store/eventSounds/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { IHeaderCells } from "store/eventSounds/types";
import { useTranslation } from "react-i18next";

const { setHeaderCells, setOpenModal, setSelectedDatasets } = actions;

const tableHeaderCells = [
  { id: "", displayName: "" },
  { id: "name", displayName: "name", sortDirection: "desc" },
  { id: "result_count", displayName: "numberOfSample" },
  { id: "created_at", displayName: "creationDate" },
  { id: "modified_at", displayName: "lastUpdateDate" },
  { id: "kebabMenu", displayName: "" },
];

const SavedDatasetTab: React.FC = () => {
  const { isFetching, datasets, onPageSort } = useData();
  const [itemClicked, setItemClicked] = useState<number | any>(null);
  const [itemClickedIds, setItemClickedIds] = useState<number[] | any>([]);
  const [isItemClicked, setIsItemClicked] = useState<number | any>(null);
  const [volume, setVolume] = useState<any>(0);
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const tt = (i: string) => t(`dataset.table.${i}`);
  const { headerCells, selectedDatasets } = useSelector(
    (state: AppState) => state.eventSounds
  );

  useEffect(() => {
    dispatch(setHeaderCells(tableHeaderCells));
  }, [dispatch]);

  const handlePageSort = (event: any) => {
    const { id } = event.currentTarget.parentElement;
    const currentCell = headerCells.find(
      (item: IHeaderCells) => item.id === id
    );
    const modifiedHeaderCells = headerCells.map((cell: IHeaderCells) => {
      if (cell.id === id) {
        return {
          ...cell,
          sortDirection: cell.sortDirection === "desc" ? "asc" : "desc",
        };
      }
      return {
        id: cell.id,
        displayName: cell.displayName,
      };
    });

    dispatch(setHeaderCells(modifiedHeaderCells));
    onPageSort({
      id: currentCell?.sortDirection === "desc" ? id : `-${id}`,
      desc: currentCell?.sortDirection === "desc" ? "asc" : "desc",
    });
  };

  const menuOpened = useSelector(
    (state: AppState) => state.layout.menu,
    shallowEqual
  );

  const onSave = useCallback(() => {
    dispatch(setOpenModal(true));
  }, [dispatch]);
  const resetState = useCallback(() => {
    dispatch(setSelectedDatasets([]));
  }, [dispatch]);

  return (
    <>
      <Filter />
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <VolumeSlider volume={volume} setVolume={setVolume} />

          <TableContainer
            style={{
              marginBottom: !!selectedDatasets.length ? "6rem" : "1rem",
            }}
          >
            <Table className={classes.table}>
              <DatasetTableHead
                handlePageSort={handlePageSort}
                headerCells={headerCells}
                tt={tt}
              />
              {datasets?.results?.length ? (
                datasets!.results!.map((dataset: IDataset) => {
                  return (
                    <DatasetTableBody
                      key={dataset.id}
                      datasetItem={dataset}
                      id={dataset.id}
                      itemClicked={itemClicked}
                      setItemClicked={setItemClicked}
                      isItemClicked={isItemClicked}
                      setIsItemClicked={setIsItemClicked}
                      itemClickedIds={itemClickedIds}
                      setItemClickedIds={setItemClickedIds}
                      volume={volume}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </Table>
          </TableContainer>
          <Footer
            opened={menuOpened}
            onSave={onSave}
            resetState={resetState}
            data={selectedDatasets}
            cancel="datasetGrouping.button.cancel"
            cta="datasetGrouping.button.create"
          />
          <SaveDatasetModal />
        </>
      )}
    </>
  );
};

export default SavedDatasetTab;

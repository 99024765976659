import Combined from "./cells/combinedCells";
import { Column } from "react-table";
import { INewPlacementList } from "types/placement";
import { TFunction } from "i18next";

export const createTinyColumns = (
  t: TFunction,
  setIsPlaying: any,
  setPlayingAll: any,
  volume: number
): Column<INewPlacementList>[] => [
  {
    id: "combined",
    Header: "",
    accessor: ({ label, chunks, isLabelSound }: any) => ({
      t,
      label,
      chunks,
      isLabelSound,
      setIsPlaying,
      setPlayingAll,
      volume,
    }),
    Cell: Combined,
    custom: {
      hidePriority: 0,
    },
  },
];

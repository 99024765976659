import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Pickers } from "./components/pickers";
import { ModalWrap } from "components/events/components/modalWrap";
import { useStyles } from "./styles";
import { EventTypesSelect } from "./components/eventTypeSelect";
import { MaintenanceTypesSelect } from "./components/maintenanceTypeSelect";
import { Comments } from "./components/comments";
import { Labels } from "./components/labels";
import addEvent from "api/handlers/event/addEvent";
import addSingleEvent from "api/handlers/event/addSingleEvent";
import patchPairEvent from "api/handlers/event/patchPairEvent";
import patchEvent from "api/handlers/event/patchEvent";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  updateLabels,
  fetchEventData,
  updateFailures,
  fetchLastEventBeforeStart,
  fetchFirstEventAfterEnd,
  fetchOneEventBetweenStartAndEnd,
} from "store/eventModal/slice";
import { EModalTypes, ELabelDateType } from "store/eventModal/types";
import { refetchEventsSilent } from "store/events/actions";
import { refetchMachinesSilent } from "store/machinesList/actions";
import { AppState } from "store";
import { useSnackbar } from "notistack";
import { Form } from "react-final-form";
import { hardCodedEvents, singleEvents, findPairEvent } from "./helpers";
import { uniqueArray } from "shared/helpers";
import Failures from "./components/failures";
import { EGroupedTypes } from "shared/eventTypes";
import { createSelector } from "reselect";
import { loadMaintenanceType } from "store/maintenanceType/actions";
import { TEventStatus, TMaintenanceType } from "types/event";
import { AlertStatusSelect } from "./components/alertStatus";
import { loadEventStatuses } from "store/eventStatuses/actions";
import Divider from "../typography/heading/divider";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "../radio";
import useAlertSeverities from "dataHooks/events/alertSeverities";
import RelatedEventSelect from "components/machine/detail/general/RelatedEventSelect";
import Spinner from "components/spinner";
import Checkbox from "components/checkbox";
import { Attachments } from "components/attachments";
import { fetchPlacements } from "store/machineDetail/placements/actions";
import TextField from "components/textField";
import { Field } from "react-final-form";
import { maxCharacters } from "shared/form/validators";
import TextFieldMUI from "@mui/material/TextField";
import CurrencySelect from "./components/CurrencySelect";
import Heading1 from "components/typography/heading/heading1";
import ChatWindow from "./components/chatWindow";
import ScreenHeading from "screens/sharedParts/screenHeading";
import ButtonTabs from "components/buttonTab";
import { alertEventDetailsTypes } from "enums/eventDetails";
import { useMediaQuery, useTheme } from "@mui/material";

interface IEventModal {
  open: boolean;
  machineId: number;
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  eventType?: any;
  modalType: EModalTypes;
  id?: number;
  relatedId?: number;
  isSingle?: boolean;
  maintenanceType?: number | null;
  alertSeverity?: string;
  onSuccessCreateEventCallback?: () => void;
  onSuccessUpdateEventCallback?: () => void;
  modalState?: string | null;
}

const getMaintenanceTypes = createSelector(
  (state: AppState) => state.maintenanceTypes,
  (maintenanceTypes: any) => {
    let data =
      maintenanceTypes.data?.map((item: TMaintenanceType) => ({
        value: item.id,
        text: item.codename,
      })) ?? undefined;
    if (data) data = [{ value: 0, text: "None" }, ...data];
    return {
      data,
      loading: maintenanceTypes.loading,
    };
  }
);

const getEventStatuses = createSelector(
  (state: AppState) => state.eventStatuses,
  (eventStatuses: any) => {
    let data =
      eventStatuses.data?.map((item: TEventStatus) => ({
        value: item.codename,
        text: item.codename,
      })) ?? undefined;
    return {
      data,
      loading: eventStatuses.loading,
    };
  }
);

export const EventModal = memo(
  ({
    open,
    machineId,
    modalType,
    eventType,
    id,
    relatedId,
    isSingle: inputIsSingle = false,
    onCancel,
    alertSeverity,
    onSuccessCreateEventCallback,
    onSuccessUpdateEventCallback,
    modalState,
  }: IEventModal) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const dispatch: any = useDispatch();
    const [isExpand, setIsExpand] = useState(false);
    const {
      eventModal: {
        errors,
        modalType: modal,
        eventType: event,
        eventId,
        maintenanceType: maintenance,
        start,
        end,
        labelGroups,
        failures,
        newFailures,
        status,
        statusDefault,
        isCritical,
        isCriticalDefault,
        presumedCauses,
        recommendedAction,
        anomalyTrend,
        diagDescription,
        eventDetail,
        loading: eventModalLoading,
        isHidden,
        relatedPairEvent: relatedPair,
        estimatedPreventedDamageAmount,
        estimatedPreventedDamageCurrency,
        isMessageInUse,
      },
      auth: { user },
      machineDetail: {
        sounds: { timezoneOffset },
      },
    } = useSelector((state: AppState) => state);

    const isSingle = useMemo(
      () =>
        inputIsSingle ||
        event === "significant_nominal_state_change" ||
        eventType?.codename === "significant_nominal_state_change",
      [inputIsSingle, event, eventType?.codename]
    );

    useEffect(() => {
      dispatch(fetchPlacements({ page: 1, pageSize: 9999, machineId }));
    }, [dispatch, machineId]);

    const [alertSeverities, loading] = useAlertSeverities();

    const [relatedPairEvent, setRelatedPairEvent] = useState<number | string>(
      ""
    );
    useEffect(() => setRelatedPairEvent(relatedPair ?? ""), [relatedPair]);
    const [radioOptions, setRadioOptions] = useState<
      Array<{
        value: string;
        label: string;
      }>
    >([]);

    const [activeRadioOption, setActiveRadioOption] = useState(
      !!alertSeverity
        ? radioOptions.find((item: any) => item.value === alertSeverity)?.value
        : ""
    );

    const labelSupportedEvents: string[] = [
      EGroupedTypes.anomaly,
      EGroupedTypes.broken,
      EGroupedTypes.maintenance,
      EGroupedTypes.nsw_maintenance,
    ];

    const eventsWithAdminEvents = useMemo(() => {
      if (modalType !== "create" && isSingle && !inputIsSingle) {
        return hardCodedEvents(t).filter(
          (i: any) => i.value === "significant_nominal_state_change"
        );
      }
      if (user?.role === "nsw_admin") {
        return [
          ...hardCodedEvents(t),
          { group: t("eventTypeGroups.nguard_upgrade") },
          {
            value: "nsw_maintenance",
            text: t("eventTypeNames.nsw_maintenance"),
            tooltip: t("eventTypeDescriptions.nsw_maintenance"),
          },
        ];
      }
      return hardCodedEvents(t);
    }, [t, user, modalType, isSingle, inputIsSingle]);

    const validateModal = useMemo(() => {
      if (
        labelGroups.length &&
        labelGroups.some(
          (label: any) => !label.labels.length && !label.toDelete
        )
      ) {
        return true;
      }
      if (
        newFailures
          .map((failure: any) =>
            failure.failure
              .map(
                (type: any, idx: number) =>
                  type === null && !failure.other_failure[idx]
              )
              .some((i: any) => i)
          )
          .some((i: any) => i)
      ) {
        return true;
      }
      if (modal === EModalTypes.finish) {
        return !event || !start || !end || !!errors.start || !!errors.end;
      }
      if (modal === "create") {
        return (
          !event ||
          !start ||
          !!errors.start ||
          (end || errors.end ? !!errors.end : false)
        );
      }
      return (
        !event ||
        (!start && !end) ||
        !!errors.start ||
        !!errors.end ||
        (status === "resolved" && !activeRadioOption)
      );
    }, [
      end,
      errors.end,
      errors.start,
      event,
      modal,
      start,
      labelGroups,
      newFailures,
      status,
      activeRadioOption,
    ]);

    const [createEventMutation, { status: createStatus }] = useMutation(
      addEvent,
      {
        onSuccess: (data: any) => {
          onSuccessCreateEventCallback && onSuccessCreateEventCallback();
          return data;
        },
        onError: () => {
          return {};
        },
      }
    );

    const [createSingleEventMutation, { status: createSingleStatus }] =
      useMutation(addSingleEvent, {
        onSuccess: (data: any) => {
          onSuccessCreateEventCallback && onSuccessCreateEventCallback();
          return data;
        },
        onError: () => {
          return {};
        },
      });

    const [updatePairEventMutation, { status: editPairStatus }] = useMutation(
      patchPairEvent,
      {
        onSuccess: () => {
          enqueueSnackbar(
            t(
              modalType === EModalTypes.finish
                ? "eventModal.actions.success.finish"
                : "eventModal.actions.success.edit"
            )
          );
          dispatch(refetchEventsSilent());
          dispatch(refetchMachinesSilent());
          onSuccessUpdateEventCallback && onSuccessUpdateEventCallback();
          onCancel();
        },
      }
    );

    const [updateEventMutation, { status: editStatus }] = useMutation(
      patchEvent,
      {
        onSuccess: () => {
          enqueueSnackbar(
            t(
              modalType === EModalTypes.finish
                ? "eventModal.actions.success.finish"
                : "eventModal.actions.success.edit"
            )
          );
          onSuccessUpdateEventCallback && onSuccessUpdateEventCallback();
          dispatch(refetchEventsSilent());
          // refetch after 1s and 5s to show mail icon (mail is sent asynchronously)
          setTimeout(() => dispatch(refetchEventsSilent()), 1000);
          setTimeout(() => dispatch(refetchEventsSilent()), 5000);
          onCancel();
        },
      }
    );

    const setEvent = useCallback(
      (event: any) => {
        event.stopPropagation();
        dispatch(actions.setEventType({ eventType: event.target.value }));
      },
      [dispatch]
    );

    const setStatus = useCallback(
      (status: any) => {
        dispatch(actions.setAlertStatus({ status }));
      },
      [dispatch]
    );

    const setIsHidden = useCallback(
      (isHidden: any) => {
        dispatch(actions.setIsHidden({ isHidden }));
      },
      [dispatch]
    );

    const setMaintenanceType = useCallback(
      (event: any) => {
        event.stopPropagation();
        dispatch(
          actions.setMaintenanceType({ maintenanceType: event.target.value })
        );
      },
      [dispatch]
    );
    const setEstimatedCurrency = useCallback(
      (event: any) => {
        event.stopPropagation();
        dispatch(
          actions.setEstimatedCurrency({ currency: event.target.value })
        );
      },
      [dispatch]
    );
    const setEstimatedAmount = useCallback(
      (event: any) => {
        event.stopPropagation();
        dispatch(
          actions.setEstimatedAmount({
            amount: event.target.value,
          })
        );
      },
      [dispatch]
    );

    const noteRef = React.useRef<any>(null);

    const startEventId = useMemo(() => {
      if (!relatedId) {
        return eventId;
      }
      if (
        eventType?.codename &&
        eventType!.codename.indexOf("_start") !== eventType!.codename.length - 6
      ) {
        return relatedId;
      } else {
        return eventId;
      }
    }, [eventId, relatedId, eventType]);

    const onSubmitEvent = useCallback(
      async ({
        startMessageField,
        endMessageField,
        status,
        isCritical,
        diagDescription,
        recommendedAction,
        anomalyTrend,
        presumedCauses,
      }: {
        startMessageField: any;
        endMessageField: any;
        status: any;
        isCritical: any;
        diagDescription: any;
        recommendedAction: any;
        anomalyTrend: any;
        presumedCauses: any;
      }) => {
        let data: any = {
          machine: machineId,
        };

        if (start) {
          data[ELabelDateType.start] = {
            type: `${event}_start`,
            created_at: start,
            note: startMessageField || "",
            maintenance_type: !!maintenance ? maintenance : null,
          };
        }
        if (end) {
          data[ELabelDateType.end] = {
            type: `${event}_end`,
            created_at: end,
            note: endMessageField || "",
          };
        }

        if (modal === "create") {
          data.start.placements = uniqueArray(
            newFailures.map((i: any) => i.placement)
          ).filter((i: any) => typeof i === "number");
          data.start.note = noteRef?.current?.value || null;
          const { start } =
            isSingle && !inputIsSingle
              ? ({ start: await createSingleEventMutation(data) } ?? {})
              : ((await createEventMutation(data)) ?? {});
          if (start?.id) {
            dispatch(updateFailures(start.id));
            enqueueSnackbar(t("eventModal.actions.success.create"));
            dispatch(refetchEventsSilent());
            dispatch(refetchMachinesSilent());
            onCancel();
          }
        } else {
          if (isSingle) {
            const placements = uniqueArray(
              newFailures.map((i: any) => i.placement)
            ).filter((i: any) => typeof i === "number");
            updateEventMutation({
              id: eventId!,
              payload: {
                note: startMessageField,
                status,
                is_critical: isCritical < 0 ? null : !!isCritical,
                diag_description: diagDescription || "",
                recommended_action: recommendedAction || "",
                anomaly_trend: anomalyTrend || "",
                presumed_causes: presumedCauses || "",
                related_pair_event:
                  relatedPairEvent === "" ? null : relatedPairEvent,
                alert_severity: activeRadioOption,
                is_hidden: isHidden,
                createdAt:
                  isSingle && !inputIsSingle && start
                    ? new Date(start)
                    : undefined,
                estimated_prevented_damage_amount: Number(
                  estimatedPreventedDamageAmount
                )?.toFixed(),
                estimated_prevented_damage_currency:
                  estimatedPreventedDamageCurrency,
                placements,
              },
            });
          } else {
            const placements = uniqueArray(
              newFailures.map((i: any) => i.placement)
            ).filter((i: any) => typeof i === "number");
            updatePairEventMutation({
              id: eventId,
              ...data,
              start: {
                ...data.start,
                placements,
              },
            });
          }
          dispatch(updateFailures(startEventId!));
        }
        dispatch(updateLabels({ labelGroups, timezoneOffset, machineId }));
      },
      [
        dispatch,
        timezoneOffset,
        createEventMutation,
        createSingleEventMutation,
        end,
        event,
        eventId,
        maintenance,
        isSingle,
        inputIsSingle,
        machineId,
        modal,
        start,
        updateEventMutation,
        updatePairEventMutation,
        labelGroups,
        failures,
        newFailures,
        maintenance,
        activeRadioOption,
        relatedPairEvent,
        isHidden,
        relatedId,
        estimatedPreventedDamageAmount,
        estimatedPreventedDamageCurrency,
        enqueueSnackbar,
        onCancel,
        startEventId,
        t,
      ]
    );

    useEffect(() => {
      if (event) {
        dispatch(fetchEventData({ machineId, event }));
      }
    }, [dispatch, machineId, event, eventType]);

    useEffect(() => {
      if (eventType) {
        dispatch(
          actions.setEventType({
            eventType: findPairEvent(eventType.codename)!,
          })
        );
        dispatch(actions.setModal({ modalType }));
        dispatch(actions.setModal({ modalType }));
        dispatch(loadEventStatuses());
      }
    }, [modalType, eventType, dispatch]);

    useEffect(() => {
      if (id) {
        dispatch(actions.setEventId({ eventId: id }));
      }
    }, [dispatch, id]);

    useEffect(() => {
      return () => {
        dispatch(actions.setReset());
      };
    }, [dispatch]);

    const { data: maintenanceTypes, loading: maintenanceTypeLoading } =
      useSelector(getMaintenanceTypes);

    const { data: eventStatuses } = useSelector(getEventStatuses);

    useEffect(() => {
      if (!maintenanceTypeLoading && !maintenanceTypes) {
        dispatch(loadMaintenanceType());
      }
    }, [dispatch, maintenanceTypes, maintenanceTypeLoading]);

    useEffect(() => {
      if (!!alertSeverities) {
        setRadioOptions(
          alertSeverities.map((severity: any) => ({
            value: severity.codename,
            label: t(`severities.${severity.codename}`),
          }))
        );
      }
    }, [alertSeverities, t]);

    useEffect(() => {
      if (alertSeverity && alertSeverities?.length) {
        const picked =
          alertSeverities.find((item: any) => item.codename === alertSeverity)
            ?.codename || "";

        setActiveRadioOption(picked);
      }
    }, [alertSeverities, alertSeverity, radioOptions]);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setActiveRadioOption((event.target as HTMLInputElement).value);
    };

    useEffect(() => {
      const notEventId: number[] = [];
      if (eventDetail) {
        notEventId.push(eventDetail.id);
        if (eventDetail.related_event) {
          notEventId.push(eventDetail.related_event.id);
        }
      }
      if (modalType === EModalTypes.edit && notEventId.length === 0) {
        return;
      }
      if (event) {
        if (start && modalType !== EModalTypes.finish) {
          dispatch(
            fetchLastEventBeforeStart({
              machineId,
              event,
              to: start,
              notEventId,
            })
          );
        }
        if (end) {
          dispatch(
            fetchFirstEventAfterEnd({
              machineId,
              event,
              from: end,
              notEventId,
            })
          );
        }
        if (start) {
          dispatch(
            fetchOneEventBetweenStartAndEnd({
              machineId,
              event,
              from: start,
              to: end,
              notEventId,
            })
          );
        }
      }
    }, [dispatch, machineId, event, eventDetail, end, start, modalType]);

    const [noteValid, setNoteValid] = React.useState<boolean>(true);

    const [tab, setTab] = React.useState(alertEventDetailsTypes.eventDetails);
    const TabData = useMemo(() => {
      const tabs = [
        {
          label: t(`eventModal.titles.${modal}`),
          value: alertEventDetailsTypes.eventDetails,
        },
        {
          label: t("eventModal.titles.aiAssistant"),
          value: alertEventDetailsTypes.aiAssistant,
        },
      ];
      return tabs;
    }, []);

    const handleTabChange = useCallback((v: any) => {
      setTab(alertEventDetailsTypes[v as keyof typeof alertEventDetailsTypes]);
    }, []);

    const eventAlert =
      event === "anomaly" ||
      event === "anomaly_warning" ||
      event === "anomaly_ok";

    const theme = useTheme();
    const downMd = useMediaQuery(theme.breakpoints.down(768), {
      noSsr: true,
    });

    return (
      <Form
        onSubmit={onSubmitEvent}
        render={({ handleSubmit, values }) => {
          const customerInformedConditionError =
            values.status === "customer_informed" &&
            isCritical !== null &&
            !values.presumedCauses &&
            !values.recommendedAction &&
            !values.anomalyTrend &&
            !values.diagDescription;
          const submitDisabled =
            validateModal || customerInformedConditionError || !noteValid;
          return (
            <form id="eventTypesModalForm" onSubmit={handleSubmit}>
              <ModalWrap
                open={open}
                cta={t(`eventModal.actions.${modal}`)}
                submitDisabled={submitDisabled || isMessageInUse!}
                onCancel={onCancel}
                className={classes.modalWrapper}
                loading={
                  createStatus === "loading" ||
                  createSingleStatus === "loading" ||
                  editStatus === "loading" ||
                  editPairStatus === "loading" ||
                  maintenanceTypeLoading ||
                  eventModalLoading
                }
                onSubmit={handleSubmit}
                toolTip={
                  isMessageInUse! ? t("eventModal.confirmation.tooltip") : ""
                }
                width={eventAlert && !downMd ? "100%" : undefined}
                border={eventAlert}
              >
                {!eventDetail && modalType !== EModalTypes.create ? (
                  <Spinner />
                ) : (
                  <>
                    {eventAlert && downMd ? (
                      <>
                        <ButtonTabs
                          tabs={TabData}
                          value={tab}
                          onChange={handleTabChange}
                        />
                        {tab === alertEventDetailsTypes.eventDetails ? (
                          <Box
                            sx={{
                              height: "100%",
                              overflow: "hidden",
                            }}
                          >
                            <Box className={classes.root}>
                              <ScreenHeading>
                                <Heading1 id="testMachineDetailHeading">
                                  {t(`eventModal.titles.${modal}`)}
                                </Heading1>
                              </ScreenHeading>
                            </Box>
                            <Box
                              className={classes.form}
                              pb={`${theme.spacing(5)}!important`}
                            >
                              <Box
                                className={
                                  eventAlert
                                    ? classes.alertDetail
                                    : classes.detail
                                }
                                flex={1}
                                mb={2}
                              >
                                <EventTypesSelect
                                  event={event!}
                                  disabled={
                                    modalType !== "create" &&
                                    !(isSingle && !inputIsSingle)
                                  }
                                  setEvent={setEvent}
                                  eventTypes={
                                    inputIsSingle
                                      ? singleEvents(t)
                                      : eventsWithAdminEvents
                                  }
                                />
                              </Box>
                              <Box
                                className={
                                  eventAlert
                                    ? classes.alertDetail
                                    : classes.detail
                                }
                                flex={1}
                              >
                                <Pickers
                                  isSingle={isSingle}
                                  editableSingle={isSingle && !inputIsSingle}
                                  eventId={eventId}
                                  start={start}
                                  end={end}
                                  modal={modal}
                                />
                                <Box className={classes.select}>
                                  <TextFieldMUI
                                    label={t(
                                      "eventModal.titles.estimatedSavings"
                                    )}
                                    variant="outlined"
                                    type="number"
                                    onChange={setEstimatedAmount}
                                    value={
                                      eventDetail?.estimated_prevented_damage_amount
                                    }
                                    inputProps={{
                                      min: 0,
                                    }}
                                    fullWidth
                                  />
                                </Box>
                                <CurrencySelect
                                  currency={
                                    eventDetail?.estimated_prevented_damage_currency
                                  }
                                  setCurrency={setEstimatedCurrency}
                                  currencySelectId="currencyTypeSelect"
                                  selectLabel={t(
                                    "eventModal.titles.currencyTypes"
                                  )}
                                />
                              </Box>
                              {user?.role === "nsw_admin" && (
                                <Box mt={4}>
                                  <Divider line noMargin>
                                    {t("eventVisibility.title")}
                                  </Divider>
                                  <Box mt={2} />
                                  <Checkbox
                                    label={t("eventVisibility.hidden")}
                                    checked={isHidden}
                                    onChange={() => setIsHidden(!isHidden)}
                                  />
                                </Box>
                              )}
                              <Comments
                                modalType={modal}
                                isSingle={isSingle}
                                modal={modal}
                                modalState={modalState}
                              />
                              {startEventId && (
                                <Attachments
                                  event={startEventId}
                                  hidePagination={true}
                                />
                              )}
                              {(event === "anomaly" ||
                                event === "anomaly_warning") && (
                                <AlertStatusSelect
                                  disabled={false}
                                  setStatus={setStatus}
                                  alertStatuses={eventStatuses}
                                  status={status}
                                  statusDefault={statusDefault}
                                  isCritical={isCritical}
                                  isCriticalDefault={isCriticalDefault}
                                  presumedCauses={presumedCauses}
                                  recommendedAction={recommendedAction}
                                  anomalyTrend={anomalyTrend}
                                  diagDescription={diagDescription}
                                />
                              )}
                              {status === "resolved" && (
                                <>
                                  {loading ? (
                                    <Spinner />
                                  ) : (
                                    <Box p={2}>
                                      <Divider line noMargin>
                                        {t(
                                          "machine.detail.eventsList.resolveSeverity.title"
                                        )}
                                      </Divider>
                                      <RadioGroup
                                        value={activeRadioOption}
                                        onChange={handleRadioChange}
                                        className={classes.radio}
                                      >
                                        {radioOptions.map((item: any) => (
                                          <Radio
                                            key={item.label + item.value}
                                            value={item.value}
                                            activeValue={activeRadioOption}
                                            label={item.label}
                                          />
                                        ))}
                                      </RadioGroup>
                                    </Box>
                                  )}
                                </>
                              )}
                              {machineId &&
                                (event === "anomaly" ||
                                  event === "anomaly_warning") && (
                                  <RelatedEventSelect
                                    machine={machineId}
                                    value={relatedPairEvent}
                                    relatedPairEvent={relatedPair}
                                    onChange={(val: any) =>
                                      setRelatedPairEvent(val)
                                    }
                                    className={classes.relatedEvent}
                                  />
                                )}
                              {eventType?.codename &&
                              labelSupportedEvents.includes(
                                findPairEvent(eventType.codename)
                              ) ? (
                                <Labels />
                              ) : null}
                              <Divider />
                            </Box>
                          </Box>
                        ) : (
                          <></>
                        )}
                        {tab === alertEventDetailsTypes.aiAssistant ? (
                          <Box height="100%" overflow="hidden" pt={3} pb={2}>
                            <ChatWindow eventId={eventId!} />
                          </Box>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <Box
                        className={eventAlert ? classes.contentWrapper : ""}
                        sx={{
                          height: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          flexBasis="50%"
                          p={eventAlert ? 3 : "unset"}
                          pr="unset"
                          height="100%"
                        >
                          <Box
                            className={
                              eventAlert ? classes.rootAlert : classes.root
                            }
                          >
                            <ScreenHeading>
                              <Heading1 id="testMachineDetailHeading">
                                {t(`eventModal.titles.${modal}`)}
                              </Heading1>
                            </ScreenHeading>
                          </Box>
                          <Box
                            className={
                              eventAlert ? classes.formAlert : classes.formEvent
                            }
                          >
                            <Box
                              className={
                                eventAlert
                                  ? classes.alertDetail
                                  : classes.detail
                              }
                              flex={1}
                              mb={2}
                            >
                              <EventTypesSelect
                                event={event!}
                                disabled={
                                  modalType !== "create" &&
                                  !(isSingle && !inputIsSingle)
                                }
                                setEvent={setEvent}
                                eventTypes={
                                  inputIsSingle
                                    ? singleEvents(t)
                                    : eventsWithAdminEvents
                                }
                              />
                              {modal !== EModalTypes.finish &&
                              (eventType?.codename === "maintenance_start" ||
                                eventType?.codename === "maintenance_end" ||
                                event === "maintenance") ? (
                                <MaintenanceTypesSelect
                                  value={maintenance ?? 0}
                                  setMaintenanceType={setMaintenanceType}
                                  maintenanceTypes={maintenanceTypes || []}
                                />
                              ) : null}
                            </Box>
                            <Box
                              className={
                                eventAlert
                                  ? classes.alertDetail
                                  : classes.detail
                              }
                              flex={1}
                            >
                              <Pickers
                                isSingle={isSingle}
                                editableSingle={isSingle && !inputIsSingle}
                                eventId={eventId}
                                start={start}
                                end={end}
                                modal={modal}
                              />
                              {(event === "anomaly" ||
                                event === "anomaly_warning" ||
                                event === "anomaly_ok") && (
                                <>
                                  <Box className={classes.select}>
                                    <TextFieldMUI
                                      label={t(
                                        "eventModal.titles.estimatedSavings"
                                      )}
                                      variant="outlined"
                                      type="number"
                                      onChange={setEstimatedAmount}
                                      value={
                                        eventDetail?.estimated_prevented_damage_amount
                                      }
                                      inputProps={{
                                        min: 0,
                                      }}
                                      fullWidth
                                    />
                                  </Box>
                                  <CurrencySelect
                                    currency={
                                      eventDetail?.estimated_prevented_damage_currency
                                    }
                                    setCurrency={setEstimatedCurrency}
                                    currencySelectId="currencyTypeSelect"
                                    selectLabel={t(
                                      "eventModal.titles.currencyTypes"
                                    )}
                                  />
                                </>
                              )}
                            </Box>
                            {(event === "anomaly" ||
                              event === "anomaly_warning" ||
                              event === "anomaly_ok") &&
                              user?.role === "nsw_admin" && (
                                <Box mt={4}>
                                  <Divider line noMargin>
                                    {t("eventVisibility.title")}
                                  </Divider>
                                  <Box mt={2} />
                                  <Checkbox
                                    label={t("eventVisibility.hidden")}
                                    checked={isHidden}
                                    onChange={() => setIsHidden(!isHidden)}
                                  />
                                </Box>
                              )}

                            {modal !== EModalTypes.finish &&
                            (eventType?.codename === "maintenance_start" ||
                              eventType?.codename === "maintenance_end" ||
                              event === "maintenance" ||
                              eventType?.codename === "broken_start" ||
                              eventType?.codename === "broken_end" ||
                              event === "broken" ||
                              eventType?.codename === "data_label_start" ||
                              eventType?.codename === "data_label_end" ||
                              event === "data_label" ||
                              event === "nominal_sound" ||
                              event === "additional_training_data" ||
                              event === "significant_nominal_state_change") ? (
                              <Failures
                                event={
                                  eventType?.codename === "maintenance_end" ||
                                  eventType?.codename === "broken_end" ||
                                  eventType?.codename === "data_label_end"
                                    ? relatedId!
                                    : eventId!
                                }
                                modelTrainingType={
                                  event === "nominal_sound" ||
                                  event === "additional_training_data" ||
                                  event === "significant_nominal_state_change"
                                }
                                modalType={modalType}
                                onlyPlacements={
                                  event === "nominal_sound" ||
                                  event === "additional_training_data" ||
                                  event === "significant_nominal_state_change"
                                }
                              />
                            ) : null}
                            {modalType === EModalTypes.create && (
                              <Box
                                mb={4}
                                sx={{
                                  "& .MuiInputBase-input": {
                                    padding: 0,
                                  },
                                }}
                              >
                                <Divider line>
                                  {t("eventModal.notes.title")}
                                </Divider>
                                <Field
                                  name="note"
                                  inputRef={noteRef}
                                  component={TextField}
                                  label={t("eventModal.notes.note")}
                                  multiline
                                  fullWidth
                                  validate={(value: any) => {
                                    const res = maxCharacters(2000)(value);
                                    setNoteValid(!res);
                                    return res;
                                  }}
                                />
                              </Box>
                            )}
                            <Comments
                              modalType={modal}
                              isSingle={isSingle}
                              modal={modal}
                              modalState={modalState}
                            />
                            {startEventId && (
                              <Attachments
                                event={startEventId}
                                hidePagination={true}
                              />
                            )}
                            {(event === "anomaly" ||
                              event === "anomaly_warning") && (
                              <AlertStatusSelect
                                disabled={false}
                                setStatus={setStatus}
                                alertStatuses={eventStatuses}
                                status={status}
                                statusDefault={statusDefault}
                                isCritical={isCritical}
                                isCriticalDefault={isCriticalDefault}
                                presumedCauses={presumedCauses}
                                recommendedAction={recommendedAction}
                                anomalyTrend={anomalyTrend}
                                diagDescription={diagDescription}
                              />
                            )}
                            {status === "resolved" && (
                              <>
                                {loading ? (
                                  <Spinner />
                                ) : (
                                  <Box p={2}>
                                    <Divider line noMargin>
                                      {t(
                                        "machine.detail.eventsList.resolveSeverity.title"
                                      )}
                                    </Divider>
                                    <RadioGroup
                                      value={activeRadioOption}
                                      onChange={handleRadioChange}
                                      className={classes.radio}
                                    >
                                      {radioOptions.map((item: any) => (
                                        <Radio
                                          key={item.label + item.value}
                                          value={item.value}
                                          activeValue={activeRadioOption}
                                          label={item.label}
                                        />
                                      ))}
                                    </RadioGroup>
                                  </Box>
                                )}
                              </>
                            )}
                            {machineId &&
                              (event === "anomaly" ||
                                event === "anomaly_warning") && (
                                <RelatedEventSelect
                                  machine={machineId}
                                  value={relatedPairEvent}
                                  relatedPairEvent={relatedPair}
                                  onChange={(val: any) =>
                                    setRelatedPairEvent(val)
                                  }
                                  className={classes.relatedEvent}
                                />
                              )}
                            {eventType?.codename &&
                            labelSupportedEvents.includes(
                              findPairEvent(eventType.codename)
                            ) ? (
                              <Labels />
                            ) : null}
                            <Divider />
                          </Box>
                        </Box>

                        {eventAlert ? (
                          <Box
                            pt={3}
                            pb={2}
                            flexBasis="50%"
                            width="50%"
                            className={classes.chatContainer}
                          >
                            <div className={classes.chatBorder}></div>
                            <ChatWindow eventId={eventId!} />
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    )}
                  </>
                )}
              </ModalWrap>
            </form>
          );
        }}
      />
    );
  }
);

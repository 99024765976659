import * as scale from "d3-scale-chromatic";

export function clear(el) {
  if (el) {
    const context = el.getContext("2d");
    context.clearRect(0, 0, el.width, el.height);
  }
}

export function fill(el, color) {
  if (el) {
    const context = el.getContext("2d");
    context.fillStyle = color;
    context.fillRect(0, 0, el.width, el.height);
  }
}

export function drawWave(el, peaks, range) {
  if (el) {
    const context = el.getContext("2d");
    context.fillStyle = "#a61d3c";
    const multiplier = el.height / range;
    const half = el.height / 2;
    for (let i = 0, l = peaks.length; i < l; i += 2) {
      let max = peaks[i];
      let min = peaks[i + 1];
      context.fillRect(i / 2, half - half * max, 1, (max - min) * multiplier);
    }
  }
}

export function drawEnergy(el, peaks, range) {
  if (el) {
    const context = el.getContext("2d");
    context.beginPath();
    context.clearRect(0, 0, el.width, el.height);
    context.strokeStyle = "#a61d3c";
    const half = el.height / 2;
    context.moveTo(0, half * 2 - half * 2 * peaks[0]);
    for (let i = 2, l = peaks.length; i < l; i += 2) {
      let max = peaks[i];
      context.lineTo(i / 2, half * 2 - half * 2 * max);
      context.moveTo(i / 2, half * 2 - half * 2 * max);
    }
    context.stroke();
  }
}

export function drawModelResults(el, values, range) {
  if (el) {
    const context = el.getContext("2d");
    const offset = 0.05;
    const multiplier = (el.height / range) * 0.9;
    const rmsValues = values
      .map((i) => i.rmsVelocity)
      .filter((i) => i !== undefined);
    const envelopeEnergyValues = values
      .map((i) => i.envelopeEnergy)
      .filter((i) => i !== undefined);
    const maxRms = rmsValues.length ? Math.max(...rmsValues) : null;
    const maxEnvelopeEnergy = envelopeEnergyValues.length
      ? Math.max(...envelopeEnergyValues)
      : null;
    const rmsMultiplier = maxRms ? multiplier / maxRms : multiplier;
    const envelopeEnergyMultiplier = maxEnvelopeEnergy
      ? multiplier / maxEnvelopeEnergy
      : multiplier;
    for (let i = 0, l = values.length, x = 0; i < l; ++i) {
      if (values[i].loudness !== null) {
        context.fillStyle = "#00ff00";
        context.fillRect(
          x,
          el.height - values[i].loudness * multiplier + offset,
          values[i].length,
          1.5
        );
      }
      if (values[i].anomaly !== null) {
        context.fillStyle = "#ffff00";
        context.fillRect(
          x,
          el.height - values[i].anomaly * multiplier + offset,
          values[i].length,
          1.5
        );
      }
      if (values[i].envelopeEnergy !== null) {
        context.fillStyle = "#ff0000";
        context.fillRect(
          x,
          el.height -
            values[i].envelopeEnergy * envelopeEnergyMultiplier +
            offset,
          values[i].length,
          1.5
        );
      }
      if (values[i].rmsVelocity !== null) {
        context.fillStyle = "#6666ff";
        context.fillRect(
          x,
          el.height - values[i].rmsVelocity * rmsMultiplier + offset,
          values[i].length,
          1.5
        );
      }
      x += values[i].length;
    }
  }
}

export function drawFft(el, fft, range) {
  if (el) {
    const context = el.getContext("2d");
    context.fillStyle = "#a61d3c";
    const width = el.width;
    const height = el.height;
    const multiplierX = width / fft.length;
    const multiplierY = height / range;
    context.beginPath();
    for (let i = 0, l = fft.length; i < l; ++i) {
      let value = height - fft[i] * multiplierY;
      const x = i * multiplierX;
      if (i) context.lineTo(x, value);
      context.moveTo(x, value);
    }
    context.stroke();
  }
}

export function resampleFrequencies(oldMatrix, width) {
  const columnsNumber = width;
  const newMatrix = [];

  const oldPiece = 1 / oldMatrix.length;
  const newPiece = 1 / columnsNumber;

  for (let i = 0; i < columnsNumber; i++) {
    const column = new Array(oldMatrix[0].length);
    column.fill(0);

    for (let j = 0; j < oldMatrix.length; j++) {
      const oldStart = j * oldPiece;
      const oldEnd = oldStart + oldPiece;
      const newStart = i * newPiece;
      const newEnd = newStart + newPiece;

      const overlap =
        oldEnd <= newStart || newEnd <= oldStart
          ? 0
          : Math.min(Math.max(oldEnd, newStart), Math.max(newEnd, oldStart)) -
            Math.max(Math.min(oldEnd, newStart), Math.min(newEnd, oldStart));
      if (overlap > 0) {
        for (let k = 0; k < oldMatrix[0].length; k++) {
          column[k] += (overlap / newPiece) * oldMatrix[j][k];
        }
      }
    }

    newMatrix.push(Uint8Array.from(column));
  }

  return newMatrix;
}

export const palette = [];
export const paletteRGB = [];

// grayscale
for (let i = 0; i < 256; i++) {
  let color = 255 - i;
  palette[i] = `rgb(${color},${color},${color})`;
}

// magma
for (let i = 0; i < 256; i++) {
  let color = i / 256;
  palette[i] = scale.interpolateMagma(color);
  let hex = palette[i].replace("#", "0x") - 0;
  paletteRGB[i] = [hex >> 16, (hex >> 8) & 0xff, hex & 0xff];
  // palette[i] = scale.interpolateInferno(color);
  // palette[i] = scale.interpolateWarm(color);
  // palette[i] = scale.interpolateOrRd(color);
  // palette[i] = scale.interpolateRdPu(color);
}

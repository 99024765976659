import { useCallback, useEffect, useMemo, useState } from "react";
import {
  TableBody,
  TableRow,
  TableCell,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { dateToStringShort } from "shared/helpers";
import { KebabMenu } from "components/kebab";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import actions, {
  getSingleDataset,
  removeDataset,
  updateDataset,
} from "store/eventSounds/actions";
import Spinner from "components/spinner";
import RowDetails from "./rowDetails";
import ConfirmPopup from "components/confirmPopup";
import { useNavigate } from "react-router-dom";
import urls from "shared/appUrls";
import { BlackCheckbox } from "components/checkbox";
import RenameModal from "./renameModal";

const DatasetTableBody = ({
  datasetItem,
  id,
  itemClicked,
  setItemClicked,
  isItemClicked,
  setIsItemClicked,
  itemClickedIds,
  setItemClickedIds,
  volume,
}: any) => {
  const { t } = useTranslation();
  const tt = (i: string) => t(`dataset.${i}`);

  const { datasetDetails, selectedDatasets } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const history = useNavigate();
  const { isFetching, data: dataset } = datasetDetails;

  const dispatch: any = useDispatch();
  const classes = useStyles();
  const [datasetChunks, setDatasetChunks] = useState(dataset?.results);
  const [groupedChunks, setGroupedChunks] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [datasetName, setDatasetName] = useState(datasetItem.name);

  useEffect(() => {
    if (datasetChunks) {
      datasetChunks.forEach((item: any, idx: number) => {
        item.idx = idx;
      });
    }
  }, [datasetChunks]);

  useEffect(() => {
    if (
      (itemClicked === id && itemClickedIds.includes(itemClicked)) ||
      (isEditClicked &&
        datasetItem.result_count &&
        itemClickedIds.includes(datasetItem.id))
    ) {
      dispatch(getSingleDataset(itemClickedIds[0]));
    }
  }, [
    dispatch,
    itemClickedIds,
    datasetItem.id,
    datasetItem.result_count,
    id,
    isEditClicked,
    itemClicked,
  ]);

  useEffect(() => {
    if (
      (itemClicked === datasetItem.id || isEditClicked) &&
      itemClickedIds[0] === datasetItem.id &&
      dataset &&
      dataset?.results?.length
    ) {
      setDatasetChunks(dataset!.results);
    } else {
      setDatasetChunks(null);
    }
  }, [dataset, itemClicked, itemClickedIds, isEditClicked, datasetItem.id]);

  const hideKebabMenu = useCallback(() => {
    const hideKebab = new CustomEvent("hideKebab");
    const kebab = document.querySelector(
      "#kebab-devices-list-" + datasetItem.id
    );
    setIsEditClicked(false);
    kebab?.dispatchEvent(hideKebab);
  }, [datasetItem.id]);

  const onDelete = useCallback(() => {
    hideKebabMenu();
    setDeleteModal(true);
  }, [hideKebabMenu]);

  const deleteDataset = useCallback(() => {
    setDeleteModal(false);
    dispatch(removeDataset(datasetItem.id));
  }, [dispatch, datasetItem.id]);

  const onEdit = useCallback(() => {
    setItemClickedIds([datasetItem.id]);
    setIsEditClicked(true);
  }, [dispatch, datasetItem.id, setItemClickedIds]);

  const onRename = useCallback(() => {
    setItemClickedIds([datasetItem.id]);
    setRenameModal(true);
    hideKebabMenu();
  }, [dispatch, hideKebabMenu, datasetItem.id, setItemClickedIds]);

  const handleRenameSubmit = useCallback(() => {
    hideKebabMenu();
    setRenameModal(false);
    dispatch(
      updateDataset({
        name: datasetName,
        id: datasetItem.id,
        openNewTab: false,
      })
    );
  }, [hideKebabMenu, datasetItem.id, datasetName, dispatch]);

  const closeRenameMenu = useCallback(() => {
    setRenameModal(false);
  }, []);

  useEffect(() => {
    if (isEditClicked && !datasetItem.result_count) {
      history(urls.eventSounds.detailEventSelection());
      dispatch(actions.setIsSearchedClicked(true));
    } else if (
      isEditClicked &&
      datasetChunks &&
      datasetChunks.length &&
      datasetItem.result_count
    ) {
      const datasetToEdit = datasetChunks?.map((chunk: any) => ({
        placement: chunk.placement,
        start: chunk.start,
        end: chunk.end,
        representation_start: chunk.representation_start,
        representation_end: chunk.representation_end,
      }));

      const machines = datasetChunks?.map((chunk: any) => {
        return chunk.machine;
      });

      const modifiedMachines = machines?.filter((elem: number, pos: number) => {
        return machines.indexOf(elem) === pos;
      });

      history({
        pathname: urls.eventSounds.detailEventSelection(),
        search: `machine=${modifiedMachines.join(",")}`,
      });
      hideKebabMenu();
      dispatch(actions.setSelectedChunks(datasetToEdit));
      dispatch(actions.setMachineValues(modifiedMachines));
      dispatch(actions.setIsSearchedClicked(true));
    }
  }, [
    hideKebabMenu,
    isEditClicked,
    datasetChunks,
    datasetItem.result_count,
    dispatch,
    history,
  ]);

  const closeMenu = useCallback(() => {
    setDeleteModal(false);
  }, []);

  const generateItems = useMemo(() => {
    return (
      <>
        <MenuItem>
          <Button
            size="small"
            className={classes.actionButton}
            onClick={onRename}
          >
            {tt("rename")}
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            size="small"
            className={classes.actionButton}
            onClick={onEdit}
          >
            {isEditClicked ? (
              <div
                style={{
                  maxHeight: "20px",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              tt("edit")
            )}
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            size="small"
            className={classes.actionButton}
            onClick={onDelete}
          >
            {tt("delete")}
          </Button>
        </MenuItem>
      </>
    );
  }, [isEditClicked, classes.actionButton, onDelete, onEdit, onRename, tt]);

  const setIsPlaying = (index: number, id: number) => (value: boolean) =>
    setDatasetChunks((currentDatasetChunks: any[]) => {
      return currentDatasetChunks.map((chunk: any) => {
        return {
          ...chunk,
          audio_chunks: chunk.audio_chunks.map((item: any) => {
            if (item.id === id && chunk.idx === index) {
              return {
                ...item,
                isPlaying: value,
              };
            } else if (value) {
              return {
                ...item,
                isPlaying: false,
              };
            } else {
              return item;
            }
          }),
        };
      });
    });

  useEffect(() => {
    const result = datasetChunks?.reduce((acc: any[], chunk: any) => {
      const key = `${chunk.machineName} / ${chunk.placementName}`;

      const matchingGroup = acc.find((group: any) => group.label === key);

      if (matchingGroup) {
        matchingGroup.chunks.push(chunk);
      } else {
        acc.push({ label: key, chunks: [chunk] });
      }

      return acc;
    }, []);
    setGroupedChunks(result);
  }, [datasetChunks, dataset]);

  const handleClick = useCallback(
    (datasetId: number) => {
      if (selectedDatasets?.includes(datasetId)) {
        dispatch(
          actions.setSelectedDatasets(
            selectedDatasets.filter((item: any) => item !== datasetId)
          )
        );
      } else {
        dispatch(actions.setSelectedDatasets([...selectedDatasets, datasetId]));
      }
    },
    [dispatch, selectedDatasets]
  );

  return (
    <>
      <thead className={classes.break} />
      <TableBody>
        <TableRow className={classes.tr}>
          <TableCell className={classes.td}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                setItemClicked(datasetItem.id);
                setIsItemClicked(!isItemClicked);

                setItemClickedIds(
                  itemClickedIds.includes(datasetItem.id)
                    ? []
                    : [datasetItem.id]
                );
              }}
              disabled={!datasetItem.result_count}
            >
              {itemClicked === id && itemClickedIds.includes(itemClicked) ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </IconButton>
          </TableCell>
          <TableCell
            colSpan={1}
            className={`${classes.smallPadding} ${classes.td}`}
          >
            {datasetItem.name}
          </TableCell>
          <TableCell
            colSpan={1}
            className={`${classes.smallPadding} ${classes.td}`}
            align="center"
          >
            {datasetItem.result_count}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {dateToStringShort(datasetItem.created_at)}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.td}`}
            align="left"
          >
            {dateToStringShort(datasetItem.modified_at)}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.td}`}
            align="left"
          >
            <BlackCheckbox
              checked={selectedDatasets?.includes(datasetItem.id)}
              onChange={() => handleClick(datasetItem.id)}
            />
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.td}`}
            align="right"
          >
            <KebabMenu
              id={"kebab-devices-list-" + datasetItem.id}
              data={generateItems}
            />
          </TableCell>
        </TableRow>
        {itemClicked === id && itemClickedIds.includes(itemClicked) ? (
          isFetching || dataset?.id !== itemClicked ? (
            <TableRow>
              <TableCell colSpan={7}>
                <Spinner />
              </TableCell>
            </TableRow>
          ) : (
            groupedChunks?.map((item: any) => {
              return (
                <RowDetails
                  key={item.label}
                  setIsPlaying={setIsPlaying}
                  datasetItem={item}
                  volume={volume}
                />
              );
            })
          )
        ) : null}
      </TableBody>

      {deleteModal && (
        <ConfirmPopup
          onConfirm={deleteDataset}
          title={t("dataset.titles.delete")}
          text={t("dataset.titles.deleteText")}
          confirmText={t("dataset.actions.delete")}
          noControl={true}
          onCancel={closeMenu}
        />
      )}

      <RenameModal
        datasetName={datasetName}
        setDatasetName={setDatasetName}
        open={renameModal}
        onCancel={closeRenameMenu}
        onSubmit={handleRenameSubmit}
        loading={isFetching && Boolean(datasetChunks)}
      />
    </>
  );
};

export default DatasetTableBody;

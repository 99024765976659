import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { makeStyles } from "@mui/styles";
import _debounce from "lodash/debounce";
import { debounceTime } from "settings";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch } from "react-redux";
import { actions } from "store/eventModal/slice";

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: "relative",
    width: "100%",
  },
  input: {
    padding: 0,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),

    "& textarea": {
      padding: 0,
    },
  },
}));

type Props = {
  value: string;
  onSend?: (s: string) => void;
  disabled?: boolean;
};

const ChatInputField = ({ onSend, value, disabled }: Props) => {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(value);
  const classes = useStyles();

  const dispatch = useDispatch();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const inputValue = event.currentTarget.value;

      setLocalValue(inputValue);
    },
    []
  );

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (localValue) {
      dispatch(actions.setIsMessageInUse({ isMessageInUse: true }));
    } else {
      dispatch(actions.setIsMessageInUse({ isMessageInUse: false }));
    }
  }, [dispatch, localValue]);

  const debouncedSetSend = React.useMemo(
    () =>
      _debounce((input: string) => {
        onSend && onSend(input);
        setLocalValue("");
      }, debounceTime),
    []
  );

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (!e.shiftKey && e.key === "Enter") {
        e.preventDefault(); // Prevents new line
        if (localValue.trim()) {
          debouncedSetSend(localValue);
        }
      }
    },
    [localValue]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
      }
    },
    []
  );

  return (
    <TextField
      id="testSend"
      variant="outlined"
      value={localValue ? localValue : ""}
      placeholder={t("send.placeholder")}
      onChange={onChange}
      type="text"
      fullWidth
      multiline
      maxRows={5}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      className={classes.input}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={!localValue.trim()}
              onClick={() => {
                debouncedSetSend(localValue);
              }}
            >
              <SendIcon
                htmlColor={
                  !localValue.trim()
                    ? "rgba(14, 19, 44, .16)"
                    : "rgba(14, 19, 44, .48)"
                }
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ChatInputField;

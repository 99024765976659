import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { resolveSorting } from "shared/helpers";

async function getLogs(reportIds: number[]) {
  const { data } = await axiosNeuron.get(
    `${endpoints.reports.sendLogs}?report=${reportIds.join("&report=")}&ps=10000`
  );
  return data.results.reduce(
    (
      out: any,
      log: {
        report: number;
        sent_at: string;
        recipients: {
          id: number;
          email: string;
        };
      }
    ) => ({
      ...out,
      [log.report]: {
        recipients: log.recipients,
        sentAt: log.sent_at,
      },
    }),
    {}
  );
}

export default async function getReports(params: any) {
  const url = endpoints.reports.default;

  try {
    const { data } = await axiosNeuron.get(url, {
      params: {
        project: params.id,
        page: params.page,
        ps: params.pageSize,
        order_by: resolveSorting("-created_for", params?.orderBy),
      },
    });

    const sendLogsMap = await getLogs(
      Array.from(
        new Set<number>(
          data.results.map((i: any) => i.id).filter((i: any) => i !== null)
        )
      )
    );

    return {
      ...data,
      results: data.results.map((item: any) => ({
        ...item,
        sendLog: sendLogsMap[item.id] ? sendLogsMap[item.id] : null,
      })),
    };
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}

import wasmModule from "./wasmresample";

export class WasmResample {
  constructor(wasmModule) {
    this.module = wasmModule;
  }

  resample(channelData, inputSampleRate, outputSampleRate) {
    const Module = this.module;

    const resampleLinear = Module.cwrap("resample_linear", "number", [
      "number",
      "number",
      "number",
      "number",
      "number",
      "number",
    ]);
    const freeResampledData = Module.cwrap("free_resampled_data", "void", [
      "number",
    ]);

    const numInputSamples = channelData.length;
    const inputSizeBytes = channelData.length * Float32Array.BYTES_PER_ELEMENT;

    const inputPtr = Module._malloc(inputSizeBytes);
    Module.HEAPF32.set(channelData, inputPtr / Float32Array.BYTES_PER_ELEMENT);

    const outputSamplesPtr = Module._malloc(4); // uint32_t size

    const resampledPtr = resampleLinear(
      inputPtr,
      numInputSamples,
      1,
      inputSampleRate,
      outputSampleRate,
      outputSamplesPtr
    );

    // Read numOutputSamples directly from HEAP using DataView
    const outputSamplesView = new DataView(
      Module.HEAPU8.buffer,
      outputSamplesPtr,
      4
    );
    const numOutputSamples = outputSamplesView.getUint32(0, true);

    const totalOutputSamples = numOutputSamples;

    const resampledData = new Float32Array(
      Module.HEAPF32.buffer,
      resampledPtr,
      totalOutputSamples
    );

    // Copy immediately to JavaScript memory
    const resampledCopy = new Float32Array(resampledData);

    // Free memory
    Module._free(inputPtr);
    Module._free(outputSamplesPtr);
    freeResampledData(resampledPtr);

    return resampledCopy;
  }
}

var wasmResampleInstance;

(async () => {
  wasmResampleInstance = new WasmResample(await wasmModule());
})();

export const resample = (bytesData, sampleRate, newSampleRate) =>
  wasmResampleInstance.resample(bytesData, sampleRate, newSampleRate);

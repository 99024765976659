import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Spinner from "components/spinner";
import HorizontalLine from "components/horizontalLine";
import Filter from "../components/filters";
import Table from "components/table";
import Row from "components/table/row";
import Header from "components/table/header";
import useData from "dataHooks/eventSounds";
import { createColumns } from "./columns";
import { createTinyColumns } from "./tinyColumns";
import {
  IDataSelection,
  IGroupedEventSoundList,
  ILabelSound,
} from "types/eventSounds";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import {
  BooleanStringParam,
  CommaArrayNumericParam,
  CommaArrayParam,
} from "shared/customQueries";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Footer from "../components/footer";
import SaveModal from "../components/saveDatasetModal";
import actions, { getLabelData } from "store/eventSounds/actions";
import { AppState } from "store";
import Button from "components/button";
import dayjs from "dayjs";
import VolumeSlider from "../components/volumeSlider";
import useDeepCompareEffect from "use-deep-compare-effect";

const DataSelectionTab: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch: any = useDispatch();

  const [forceUpdate, setForceUpdate] = useState(0);

  const [query, setQuery] = useQueryParams({
    from: withDefault(StringParam, ""),
    to: withDefault(StringParam, ""),
    event_type: withDefault(CommaArrayParam, []),
    failure_type: withDefault(CommaArrayParam, []),
    placement_type: withDefault(CommaArrayNumericParam, []),
    machine_subcategory: withDefault(CommaArrayNumericParam, []),
    machine: withDefault(CommaArrayNumericParam, []),
    q: withDefault(StringParam, ""),
    label_subcategory: withDefault(CommaArrayNumericParam, []),
    value_min: withDefault(StringParam, ""),
    value_max: withDefault(StringParam, ""),
    isLabel: withDefault(BooleanStringParam, false),
    isEvent: withDefault(BooleanStringParam, true),
  });

  const [searchValues, setSearchValues] = useState<{
    from: Date | null;
    to: Date | null;
    event_type: string[] | null | any;
    failure_type: string[] | null | any;
    placement_type: number[] | null | any;
    machine_subcategory: number[] | null | any;
    machine: number[] | null | any;
    q: string | null | any;
    label_subcategory: number[] | null | any;
    valueMin: number | null | any;
    valueMax: number | null | any;
    isEvent: boolean;
    isLabel: boolean;
  }>({
    from: query.from ? dayjs(query?.from).toDate() : null,
    to: query.to ? dayjs(query?.to).toDate() : null,
    event_type: query.event_type.length ? query.event_type : null,
    failure_type: query.failure_type.length ? query.failure_type : null,
    placement_type: query.placement_type.length ? query.placement_type : null,
    machine_subcategory: query.machine_subcategory.length
      ? query.machine_subcategory
      : null,
    machine: query.machine.length ? query.machine : null,
    q: query.q ? query.q : null,
    label_subcategory: query.label_subcategory || null,
    valueMin: query.value_min ? query.value_min : null,
    valueMax: query.value_max ? query.value_max : null,
    isEvent: query.isEvent !== undefined ? query.isEvent : true,
    isLabel: query.isLabel !== undefined ? query.isLabel : false,
  });

  const {
    loading,
    eventSoundsData,
    initialGlobalState,
    initialEventState,
    initialLabelState,
    showMoreClickedTimesRef,
    isSearchedClicked,
    onPageChange,
    labelSoundsData,
    dataSelectionData,
  } = useData({ searchValues, forceUpdate });

  const [hideContent, setHideContent] = useState(false);

  useEffect(() => {
    if (labelSoundsData || dataSelectionData || eventSoundsData) {
      setHideContent(false);
    }
  }, [labelSoundsData, dataSelectionData, eventSoundsData]);

  const { selectedChunks } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const menuOpened = useSelector(
    (state: AppState) => state.layout.menu,
    shallowEqual
  );

  const upMd = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });

  const [groupedLabels, setGroupedLabels] = useState<any>([]);
  const [groupedEvents, setGroupedEvents] = useState<any>([]);

  const [audioChunks, setAudioChunks] = useState<any>(groupedEvents);
  const [audioChunksLabels, setAudioChunksLabels] =
    useState<any>(groupedLabels);

  const [volume, setVolume] = useState<any>(0);

  const {
    from,
    to,
    event_type,
    failure_type,
    placement_type,
    machine_subcategory,
    machine,
    q,
    label_subcategory,
    valueMin,
    valueMax,
    isEvent,
    isLabel,
  } = searchValues;

  useEffect(() => {
    setQuery(
      {
        from: from ? from?.toISOString() : undefined,
        to: to ? to?.toISOString() : undefined,
        event_type: event_type,
        failure_type: failure_type,
        placement_type: placement_type,
        machine_subcategory: machine_subcategory,
        machine: machine,
        q,
        label_subcategory: label_subcategory,
        value_min: valueMin,
        value_max: valueMax,
        isEvent,
        isLabel,
      },
      "pushIn"
    );
  }, [
    from,
    to,
    event_type,
    failure_type,
    placement_type,
    machine_subcategory,
    machine,
    q,
    setQuery,
    label_subcategory,
    valueMin,
    valueMax,
    isEvent,
    isLabel,
  ]);

  const getLabesData = (data: any) => {
    if (data && data?.results?.length) {
      const machineIds = data.results.map(
        (item: IDataSelection) => item.machine
      );
      const placementIds = data.results.map(
        (item: IDataSelection) => item.placement
      );
      const uniqueMachineIds = machineIds.filter(
        (id: number, idx: number, self: any) => {
          return self.indexOf(id) === idx;
        }
      );
      const uniquePlacementIds = placementIds.filter(
        (id: number, idx: number, self: any) => {
          return self.indexOf(id) === idx;
        }
      );

      if (uniqueMachineIds?.length && uniquePlacementIds?.length) {
        dispatch(
          getLabelData({
            machineIds: uniqueMachineIds,
            placementIds: uniquePlacementIds,
          })
        );
      }
    }
  };

  const removeBuffer = (data: any) => ({
    ...data,
    results: data?.results.map((item: any) => ({
      ...item,
      audio_chunk: {
        ...item.audio_chunk,
        buffer: undefined,
      },
    })),
  });

  const dataSelectionDataWithoutBuffer = useMemo(
    () => removeBuffer(dataSelectionData),
    [dataSelectionData]
  );
  const labelSoundsDataWithoutBuffer = useMemo(
    () => removeBuffer(labelSoundsData),
    [labelSoundsData]
  );
  const eventSoundsDataWithoutBuffer = useMemo(
    () => removeBuffer(eventSoundsData),
    [eventSoundsData]
  );

  useDeepCompareEffect(() => {
    if (isLabel && isEvent) {
      getLabesData(labelSoundsData);
      getLabesData(eventSoundsData);
    } else {
      getLabesData(dataSelectionData);
    }
  }, [
    dispatch,
    dataSelectionDataWithoutBuffer,
    labelSoundsDataWithoutBuffer,
    eventSoundsDataWithoutBuffer,
  ]);

  useEffect(() => {
    if (isLabel && isEvent) {
      setAudioChunksLabels(groupedLabels || []);
      setAudioChunks(groupedEvents || []);
    } else {
      setAudioChunks(groupedEvents || []);
    }
  }, [groupedEvents, groupedLabels]);

  const handlePageChange = (pageIndex: number) => {
    onPageChange(pageIndex);
  };

  useEffect(() => {
    const handleGrouping = (data: any) => {
      const filteredData =
        data &&
        data?.results?.filter((item: IDataSelection) => item !== undefined);
      const result = filteredData?.reduce(
        (
          acc: IGroupedEventSoundList[] | any[],
          chunk: IDataSelection | ILabelSound | any
        ) => {
          const key = `${chunk.machine} / ${chunk.placement}`;
          const isLabelSound = chunk.isLabelSound ?? false;

          const matchingGroup = acc.find(
            (group: IGroupedEventSoundList) => group.label === key
          );

          if (matchingGroup) {
            matchingGroup.chunks.push(chunk);
          } else {
            acc.push({ label: key, chunks: [chunk], isLabelSound });
          }

          return acc;
        },
        []
      );
      return result;
    };
    if (isEvent && isLabel) {
      if (isEvent) {
        const result = handleGrouping(eventSoundsData);
        setGroupedEvents(result);
      }
      if (isLabel) {
        const result = handleGrouping(labelSoundsData);
        setGroupedLabels(result);
      }
    } else {
      const result = handleGrouping(dataSelectionData);
      setGroupedEvents(result);
    }
  }, [
    dataSelectionData,
    onPageChange,
    showMoreClickedTimesRef,
    eventSoundsData,
    labelSoundsData,
  ]);

  const setIsPlaying =
    ({ id, key }: any) =>
    (value: boolean) =>
      setAudioChunks((currentAudioChunks: any) => {
        return currentAudioChunks.map((item: any) => {
          return {
            ...item,
            chunks: item.chunks.map((chunk: any) => {
              if (chunk.id === id) {
                return {
                  ...chunk,
                  isPlaying: value,
                };
              } else if (value) {
                return {
                  ...chunk,
                  isPlaying: false,
                };
              } else {
                return chunk;
              }
            }),
          };
        });
      });

  const setPlayingAll = useCallback(() => {
    setAudioChunks((currentAudioChunks: any) => {
      return currentAudioChunks.map((item: any) => {
        return {
          ...item,
          chunks: item.chunks.map((chunk: any) => ({
            ...chunk,
            isPlaying: false,
          })),
        };
      });
    });
  }, []);

  const setIsPlayingLabels =
    ({ id, key }: any) =>
    (value: boolean) =>
      setAudioChunksLabels((currentAudioChunks: any) => {
        return currentAudioChunks.map((item: any) => {
          return {
            ...item,
            chunks: item.chunks.map((chunk: any) => {
              if (chunk.id === id) {
                return {
                  ...chunk,
                  isPlaying: value,
                };
              } else if (value) {
                return {
                  ...chunk,
                  isPlaying: false,
                };
              } else {
                return chunk;
              }
            }),
          };
        });
      });

  const setPlayingAllLabels = useCallback(() => {
    setAudioChunksLabels((currentAudioChunks: any) => {
      return currentAudioChunks.map((item: any) => {
        return {
          ...item,
          chunks: item.chunks.map((chunk: any) => ({
            ...chunk,
            isPlaying: false,
          })),
        };
      });
    });
  }, []);

  const columnsMemoized = useMemo(
    () => createColumns(t, setIsPlaying, setPlayingAll, volume),
    [t, setIsPlaying, setPlayingAll, volume]
  );
  const tinyColumnsMemoized = useMemo(
    () => createTinyColumns(t, setIsPlaying, setPlayingAll, volume),
    [t, setIsPlaying, setPlayingAll, volume]
  );
  const columns: any = upMd ? columnsMemoized : tinyColumnsMemoized;

  const columnsMemoizedLabels = useMemo(
    () => createColumns(t, setIsPlayingLabels, setPlayingAllLabels, volume),
    [t, setIsPlayingLabels, setPlayingAllLabels]
  );
  const tinyColumnsMemoizedLabels = useMemo(
    () => createTinyColumns(t, setIsPlayingLabels, setPlayingAllLabels, volume),
    [t, setIsPlayingLabels, setPlayingAllLabels]
  );
  const columnsLabels: any = upMd
    ? columnsMemoizedLabels
    : tinyColumnsMemoizedLabels;

  const resetState = useCallback(() => {
    dispatch(actions.setSelectedChunks([]));
  }, [dispatch]);

  const onSave = useCallback(() => {
    dispatch(actions.setOpenModal(true));
  }, [dispatch]);

  const dataToBeSelected = useMemo(() => {
    const labelData = labelSoundsData ? labelSoundsData?.results : [];
    const eventData = eventSoundsData ? eventSoundsData?.results : [];
    const selectionData = dataSelectionData ? dataSelectionData.results : [];

    return isLabel && isEvent ? [...eventData, ...labelData] : selectionData;
  }, [dataSelectionData, eventSoundsData, labelSoundsData]);

  const handleSelectAll = useCallback(() => {
    const newSelectedData = dataToBeSelected
      .filter(
        (item: IDataSelection) =>
          !selectedChunks.some(
            (chunk: any) =>
              chunk.placement === item.placement &&
              chunk.machine === item.machine &&
              new Date(chunk.representation_start).toISOString() ===
                new Date(item.audio_chunk.start_datetime).toISOString() &&
              new Date(chunk.representation_end).toISOString() ===
                new Date(item.audio_chunk.end_datetime).toISOString()
          )
      )
      .map((item: IDataSelection | any) => ({
        placement: item.placement,
        start: item.isEventSound
          ? item.start_event.created_at
          : item.label.start_datetime,
        end: item.isEventSound
          ? item.end_event.created_at
          : item.label.end_datetime,
        representation_start: item.audio_chunk.start_datetime,
        representation_end: item.audio_chunk.end_datetime,
        label: item.isEventSound ? undefined : item.label.id,
        machine: item.machine,
      }));

    dispatch(
      actions.setSelectedChunks([...selectedChunks, ...newSelectedData])
    );
  }, [dispatch, dataToBeSelected]);

  const handleDeselectAll = useCallback(() => {
    const newData = selectedChunks.filter(
      (chunk: any) =>
        !dataToBeSelected.some(
          (item: IDataSelection) =>
            chunk.placement === item.placement &&
            chunk.machine === item.machine &&
            new Date(chunk.representation_start).toISOString() ===
              new Date(item.audio_chunk.start_datetime).toISOString() &&
            new Date(chunk.representation_end).toISOString() ===
              new Date(item.audio_chunk.end_datetime).toISOString()
        )
    );
    dispatch(actions.setSelectedChunks(newData));
  }, [selectedChunks, dataToBeSelected]);

  const isLabelFetching = labelSoundsData
    ? labelSoundsData.results?.length && !groupedLabels?.length
      ? true
      : false
    : true;
  const isEventFetching = eventSoundsData
    ? eventSoundsData.results?.length && !groupedEvents?.length
      ? true
      : false
    : true;

  return useMemo(
    () => (
      <>
        <Filter
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          setHideContent={setHideContent}
          setForceUpdate={setForceUpdate}
        />
        <VolumeSlider volume={volume} setVolume={setVolume} />
        <HorizontalLine />

        {loading ? (
          <Spinner />
        ) : (
          <Box
            style={{
              paddingBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            {isSearchedClicked && (
              <div
                style={{ display: "flex", gap: "1rem" }}
                onClick={(event) => event.stopPropagation()}
              >
                <Button color="primary" onClick={handleSelectAll}>
                  {t("dataExplorer.selectAll")}
                </Button>
                <Button
                  color="primary"
                  disabled={!selectedChunks?.length}
                  onClick={handleDeselectAll}
                >
                  {t("dataExplorer.deselectAll")}
                </Button>
              </div>
            )}
            {isSearchedClicked ? (
              <>
                {isLabel && isEvent ? (
                  <>
                    <>
                      {isEventFetching ? (
                        <Spinner />
                      ) : (
                        <Table<IGroupedEventSoundList | any>
                          showMoreClickedTimesRef={showMoreClickedTimesRef}
                          isLoading={loading || isEventFetching}
                          data={hideContent ? [] : audioChunks}
                          rowsCount={
                            eventSoundsData ? eventSoundsData.count : 0
                          }
                          columns={columns}
                          initialState={initialEventState}
                          onPageChange={handlePageChange}
                          RowComponent={Row}
                          HeaderComponent={Header}
                          RowComponentProps={{
                            rowWidth: "100%",
                            height: upMd ? "100%" : "unset",
                          }}
                          hideNoData={
                            !isEventFetching &&
                            eventSoundsData &&
                            eventSoundsData?.results?.length
                          }
                          footerSpacing={!!selectedChunks?.length}
                          pmax={101}
                        />
                      )}
                    </>
                    <>
                      {isLabelFetching ? (
                        <Spinner />
                      ) : (
                        <Table<IGroupedEventSoundList | any>
                          showMoreClickedTimesRef={showMoreClickedTimesRef}
                          isLoading={loading || isLabelFetching}
                          data={hideContent ? [] : audioChunksLabels}
                          rowsCount={
                            labelSoundsData ? labelSoundsData.count : 0
                          }
                          columns={columnsLabels}
                          initialState={initialLabelState}
                          onPageChange={handlePageChange}
                          RowComponent={Row}
                          HeaderComponent={Header}
                          RowComponentProps={{
                            rowWidth: "100%",
                            height: upMd ? "100%" : "unset",
                          }}
                          hideNoData={
                            !isLabelFetching &&
                            labelSoundsData &&
                            labelSoundsData?.results?.length
                          }
                          footerSpacing={!!selectedChunks?.length}
                          pmax={101}
                        />
                      )}
                    </>
                  </>
                ) : (
                  <>
                    {dataSelectionData?.results?.length &&
                    !groupedEvents?.length ? (
                      <Spinner />
                    ) : null}
                    <Table<IGroupedEventSoundList | any>
                      showMoreClickedTimesRef={showMoreClickedTimesRef}
                      isLoading={
                        loading ||
                        (dataSelectionData?.results?.length &&
                          !groupedEvents?.length)
                      }
                      data={hideContent ? [] : audioChunks}
                      rowsCount={
                        dataSelectionData ? dataSelectionData.count : 0
                      }
                      columns={columns}
                      initialState={initialGlobalState}
                      onPageChange={handlePageChange}
                      RowComponent={Row}
                      HeaderComponent={Header}
                      RowComponentProps={{
                        rowWidth: "100%",
                        height: upMd ? "100%" : "unset",
                      }}
                      hideNoData={
                        !loading &&
                        dataSelectionData &&
                        dataSelectionData?.results?.length
                      }
                      footerSpacing={!!selectedChunks?.length}
                      pmax={101}
                    />
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
        )}
        <Footer
          opened={menuOpened}
          onSave={onSave}
          resetState={resetState}
          data={selectedChunks}
          cancel="cancel"
          cta="confirmationBar.saveDataset"
        />
        <SaveModal />
      </>
    ),
    [
      dataSelectionData,
      eventSoundsData,
      labelSoundsData,
      selectedChunks,
      isLabel,
      isEvent,
      loading,
      isEventFetching,
      isLabelFetching,
      isSearchedClicked,
      searchValues,
      hideContent,
      audioChunks,
      menuOpened,
    ]
  );
};
export default DataSelectionTab;

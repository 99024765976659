import axiosNeuron from "api/axios";

import getAudioContext from "components/dataLabeling/audio/getAudioContext";
import { FLACDecoder } from "@wasm-audio-decoders/flac";
import { decode_wavpack } from "shared/wavpack";
import { resample } from "shared/resample";

type IParams = {
  url: string;
};

const MAX_FETCHED_SIZE = 2097151;
const MAX_SECONDS = 3;

export default async function getDecodedFile({ url }: IParams) {
  try {
    const ctx = getAudioContext();

    const res = await axiosNeuron.get(url, {
      responseType: "arraybuffer",
      headers: {
        Range: `bytes=0-${MAX_FETCHED_SIZE}`,
      },
    });
    try {
      // try to decode with FLACDecoder and resample with libsamplerate-js
      const byte_data = new Uint8Array(res.data);
      var result;
      if (
        byte_data[0] === 119 &&
        byte_data[1] === 118 &&
        byte_data[2] === 112 &&
        byte_data[3] === 107
      ) {
        result = decode_wavpack(byte_data, MAX_SECONDS);
      } else {
        const decoder = new FLACDecoder();
        await decoder.ready; // wait for the WASM to be compiled
        result = await decoder.decodeFile(byte_data);
        decoder.free();
      }
      const sampleRate: number = result.sampleRate;
      const channelData: Float32Array[] = result.channelData.slice(
        0,
        sampleRate * MAX_SECONDS
      ) as Float32Array[];
      if (sampleRate >= 8000 && sampleRate <= 96000) {
        const ret = ctx.createBuffer(1, channelData[0].length, sampleRate);
        ret.getChannelData(0).set(channelData[0]);
        return ret;
      } else {
        const newSampleRate = sampleRate < 8000 ? 8000 : 96000;
        const resampled: any = resample(channelData, sampleRate, newSampleRate);
        const ret = ctx.createBuffer(1, resampled.length, newSampleRate);
        ret.getChannelData(0).set(resampled);
        return ret;
      }
    } catch (e) {
      console.log(e);
      return await ctx.decodeAudioData(res.data);
    }
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}

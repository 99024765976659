import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type TProps = {
  machine: number;
  name: string;
  data: string;
};

export default async function addMachineManual({
  machine,
  name,
  data,
}: TProps) {
  const url = endpoints.machines.manualAttachements;
  const params = {
    machine,
    attachment: {
      name: name,
      data: data,
    },
  };
  try {
    const { data } = await axiosNeuron.post(url, params);

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}

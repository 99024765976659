import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { Box, Tooltip } from "@mui/material";
import moment from "moment";

const Submission = ({
  cell: {
    value: { sendLog },
  },
}: any) => {
  return (
    <Box>
      {sendLog ? (
        <Tooltip
          title={
            <div>
              <span>
                {moment(sendLog.sentAt).format("YYYY/MM/DD hh:mm:ss")}
              </span>
              {sendLog.recipients.map((item: { id: number; email: string }) => (
                <Box key={item.id}>{item.email}</Box>
              ))}
            </div>
          }
        >
          <EmailOutlined />
        </Tooltip>
      ) : (
        <></>
      )}
    </Box>
  );
};
export default Submission;

import { useEffect, useRef, useState } from "react";
import { Box, Grid, List } from "@mui/material";
import Heading1 from "components/typography/heading/heading1";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import ScreenHeading from "screens/sharedParts/screenHeading";
import ChatInputField from "./chatInputField";
import ChatItem from "./chatItem";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { useDispatch } from "react-redux";
import {
  getAiRecommendations,
  sendAiMessage,
  silentFetchAiRecommendations,
} from "store/eventModal/aiAssistantActions";

interface IChatWindow {
  eventId?: number;
}

const ChatWindow = ({ eventId }: IChatWindow) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const chatListRef = useRef<HTMLUListElement | any>(null);

  const {
    eventModal: { aiRecommendation, aiRecommendationLoading, silentFetch },
  } = useSelector((state: AppState) => state);

  const [chatValue, setChatValue] = useState("");

  const scrollToBottom = () => {
    if (chatListRef.current) {
      requestAnimationFrame(() => {
        chatListRef.current.scrollTop = chatListRef.current?.scrollHeight;
      });
    }
  };

  useEffect(() => {
    if (aiRecommendation?.results?.length) {
      scrollToBottom();
    }
  }, [aiRecommendation]);

  const handleSend = (val: any) => {
    setChatValue(val);
    if (val.trim()) {
      const res = dispatch(
        sendAiMessage({
          eventId: eventId!,
          text: val,
        })
      );
      setChatValue("");
      scrollToBottom();
    }
  };

  useEffect(() => {
    if (
      aiRecommendation &&
      aiRecommendation.results?.length &&
      (aiRecommendation.results?.queries?.length !==
        aiRecommendation.results?.recommendations?.length ||
        aiRecommendation.results?.status === "incomplete")
    ) {
      dispatch(silentFetchAiRecommendations());
    }
  }, [dispatch, aiRecommendation]);

  useEffect(() => {
    if (eventId) {
      dispatch(
        getAiRecommendations({
          eventId: eventId,
        })
      );
    }
  }, [dispatch, eventId]);

  return (
    <Box className={classes.root}>
      <Grid container paddingTop={0} direction="column" wrap="nowrap" pr={3}>
        <Grid item xs={12}>
          <ScreenHeading marging="unset">
            <Heading1 id="testMachineDetailHeading">
              <Box display="flex" gap={1} alignItems="center">
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <linearGradient
                      id="grad"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        stop-color="rgba(14, 19, 44, .16)"
                        stop-opacity="1"
                      />
                      <stop
                        offset="100%"
                        stop-color="rgba(14, 19, 44, 1)"
                        stop-opacity="1"
                      />
                    </linearGradient>
                  </defs>
                  <path
                    d="M256,0 C256,128 128,256 0,256 C128,256 256,384 256,512 C256,384 384,256 512,256 C384,256 256,128 256,0 Z"
                    fill="url(#grad)"
                    stroke="rgba(14, 19, 44, 1)"
                    stroke-width="1"
                  />
                </svg>

                {t(`eventModal.titles.aiAssistant`)}
              </Box>
            </Heading1>
          </ScreenHeading>
          <span className={classes.label}>{t("aiAssistant.label")}</span>
        </Grid>
      </Grid>
      <Box pr={1.4} ref={chatListRef} className={classes.form}>
        <List className={classes.list}>
          {aiRecommendationLoading ? (
            <>{t("loading")}</>
          ) : (
            <>
              {aiRecommendation && aiRecommendation.results?.length ? (
                aiRecommendation.results.map(
                  (recommendation: any, idx: number) => {
                    return (
                      <ChatItem
                        key={idx}
                        message={recommendation}
                        idx={idx}
                        dataLength={aiRecommendation.results.length - 1}
                        silentFetch={silentFetch}
                      />
                    );
                  }
                )
              ) : (
                <></>
              )}
            </>
          )}
        </List>
      </Box>
      <ChatInputField
        value={chatValue}
        onSend={handleSend}
        disabled={aiRecommendation && aiRecommendation.status === "incomplete"}
      />
    </Box>
  );
};

export default ChatWindow;

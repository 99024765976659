import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { IMachineList } from "types/machine";

type TProps = {
  id: number;
  page?: number;
  ps?: number;
};

async function getUserMap(userIds: number[]) {
  if (!userIds || !userIds.length) {
    return {};
  }
  const { data: userData } = await axiosNeuron.get(
    `${endpoints.users.default}?id=${userIds.join("&id=")}&ps=10000`
  );
  return userData.results.reduce(
    (
      out: any,
      user: {
        id: number;
        username: string;
        first_name: string;
        last_name: string;
      }
    ) => ({
      ...out,
      [user.id]:
        user.first_name || user.last_name
          ? `${user?.first_name} ${user?.last_name}`
          : user.username,
    }),
    {}
  );
}

export default async function getMachineManual({ id, page, ps }: TProps) {
  const url = endpoints.machines.manualAttachements;
  try {
    const { data } = await axiosNeuron.get(url, {
      params: {
        machine: id,
        page: page! + 1,
        ps,
      },
    });

    const results = data.results.map((i: any) => ({
      ...i,
      userId: i.modified_by_user ? i.modified_by_user : i.created_by_user,
    }));

    const userMap = await getUserMap(
      Array.from(
        new Set<number>(
          results.map((i: any) => i.userId).filter((i: any) => i !== null)
        )
      )
    );

    return {
      ...data,
      results: results.map((item: any) => ({
        ...item,
        user: userMap[item.userId] ? userMap[item.userId] : null,
      })),
    };
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}

import { Box, TableCell, TableRow } from "@mui/material";
import { useStyles } from "./styles";
import { Player } from "../Player";
import { getPeaks } from "../card";
import { CardDetail } from "./cardDetails";
import { useStyles as useCardStyles } from "components/eventSounds/components/styles";

const RowDetails = ({ datasetItem, setIsPlaying, volume }: any) => {
  const classes = useStyles();
  const cardClasses = useCardStyles();

  return (
    <TableRow>
      <TableCell
        colSpan={3}
        width={30}
        className={`${classes.td} ${classes.tdCollapsed}`}
      >
        {datasetItem.label}
      </TableCell>
      <TableCell colSpan={5} className={`${classes.td} ${classes.tdCollapsed}`}>
        <Box className={classes.contentWrapper}>
          {datasetItem.chunks.map((item: any) => {
            return item.audio_chunks.map((chunk: any) => {
              return (
                <Box className={cardClasses.container}>
                  <Player
                    buffer={chunk?.buffer}
                    getPeaks={getPeaks}
                    canvasWidth={145}
                    canvasWaveHeight={20}
                    canvasSpectroHeight={126}
                    setIsPlaying={setIsPlaying(item.idx, chunk.id)}
                    isPlaying={chunk.isPlaying}
                    volume={volume}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: ".5%",
                      right: "1%",
                    }}
                  >
                    <CardDetail
                      id={chunk.id}
                      classes={cardClasses}
                      chunk={chunk}
                      machine={item?.machineName}
                      placement={item?.placementName}
                      result={item}
                    />
                  </div>
                </Box>
              );
            });
          })}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default RowDetails;

import React from "react";
import { makeStyles } from "@mui/styles";
import Link, { LinkProps } from "@mui/material/Link";

interface Props extends LinkProps {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  link?: string;
}

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      color: theme.custom.palette.primary[100],
      cursor: "pointer",
      textDecoration: "underline",
      outline: 0,
      "&:hover": {
        textDecoration: "none",
      },
    },
    focusVisible: {
      textDecoration: "none",
    },
  };
});

const LinkComponent = (props: Props) => {
  const classes = useStyles();
  return (
    <Link
      underline="none"
      className={classes.root}
      classes={{
        root: classes.root,
        focusVisible: classes.focusVisible,
      }}
      tabIndex={0}
      onClick={props.onClick}
      id={props.id}
    >
      {props.children}
    </Link>
  );
};

export default LinkComponent;

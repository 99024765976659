import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

interface IEventParams {
  id: number;
  text: string | null;
}

export default async function sendAIMessage({ id, text }: IEventParams) {
  const url = endpoints.events.sendAIMessage(id);
  const params = {
    text,
  };

  try {
    const { data } = await axiosNeuron.post(url, params);

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}

import { EActions, TReducer, IMachineManualState } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";
import React from "react";

export const initialState: IMachineManualState = {
  manualsAttachment: null,
  isFetching: false,
  error: null,
  attachmentParams: {
    page: 0,
    ps: 10,
    id: null,
  },
  showMoreClickedTimesRef: React.createRef(),
};

const reducers: TReducer = {
  [EActions.fetchMachineManualRequest]: () => ({
    isFetching: true,
  }),
  [EActions.fetchMachineManualFail]: ({ payload: { error } }) => ({
    manualsAttachment: null,
    isFetching: false,
    error: error,
  }),
  [EActions.fetchMachineManualSuccess]: ({
    payload: { manualsAttachment },
  }) => ({
    manualsAttachment,
    // showMoreClickedTimesRef,
    isFetching: false,
    error: null,
  }),
  [EActions.setAttachmentParams]: ({ payload: { attachmentParams } }) => ({
    attachmentParams,
  }),
  [EActions.setReset]: () => ({
    ...initialState,
  }),
};

export default createReducer<IMachineManualState>(path, initialState, reducers);

import { useMemo } from "react";
import { Box } from "@mui/material";
import { CardDetail } from "./cardDetail";
import { Player } from "./Player";
import { useStyles } from "components/eventSounds/components/styles";
import Spinner from "components/spinner";

export const getPeaks = (width: number, buffer: any) => {
  const sampleSize = buffer?.length / width;
  const sampleStep = 1;
  const channels = buffer?.numberOfChannels;
  let peaks = [];
  let maxPeak = 0;
  let minPeak = 0;

  for (let c = 0; c < channels; c++) {
    const channelData = buffer?.getChannelData(c);

    for (let i = 0; i < width; i++) {
      const start = ~~(i * sampleSize);
      const end = ~~(start + sampleSize);
      let min = 0;
      let max = 0;

      for (let j = start; j < end; j += sampleStep) {
        const value = channelData[j];

        if (value > max) {
          max = value;
        }

        if (value < min) {
          min = value;
        }
      }

      if (c === 0 || max > peaks[2 * i]) {
        peaks[2 * i] = max;
      }

      if (c === 0 || min < peaks[2 * i + 1]) {
        peaks[2 * i + 1] = min;
      }

      maxPeak = Math.max(maxPeak, max);
      minPeak = Math.min(minPeak, min - 0.1);
    }
  }
  return { peaks, maxPeak, minPeak };
};

export const Card = ({
  chunk,
  id,
  setIsPlaying,
  isPlaying,
  setPlayingAll,
  volume,
}: any) => {
  const buffer = chunk.audio_chunk.buffer;
  const classes = useStyles();
  return useMemo(
    () => (
      <Box className={classes.container}>
        <Player
          buffer={buffer}
          getPeaks={getPeaks}
          canvasWidth={145}
          canvasWaveHeight={20}
          canvasSpectroHeight={126}
          setIsPlaying={setIsPlaying}
          isPlaying={isPlaying}
          volume={volume}
        />

        <div style={{ position: "absolute", top: ".5%", right: "1%" }}>
          <CardDetail
            id={id}
            getPeaks={getPeaks}
            buffer={buffer}
            chunk={chunk}
            classes={classes}
            setPlayingAll={setPlayingAll}
            volume={volume}
          />
        </div>
        {!buffer ? (
          <div className={classes.cardSpinner}>
            <div className={classes.cardSpinnerInner}>
              <Spinner />
            </div>
          </div>
        ) : null}
      </Box>
    ),
    [chunk, id, setIsPlaying, isPlaying, setPlayingAll, volume]
  );
};

import { Box, ListItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { actions, silentFetchAiRecommendations } from "store/eventModal/slice";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useTranslation } from "react-i18next";
import Loading from "./loadingChat";

interface IProps {
  message: any;
  idx: number;
  dataLength: number;
  silentFetch?: boolean;
}

const TypingEffect = ({
  text,
  speed = 10,
  t,
}: {
  text: string;
  speed?: number;
  t: any;
}) => {
  const [displayedText, setDisplayedText] = useState("");
  const dispatch = useDispatch();

  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const interval = setTimeout(() => {
        setDisplayedText(text.substring(0, index + 1));
        setIndex(index + 1);
      }, speed);
      return () => {
        clearTimeout(interval);
      };
    } else {
      dispatch(actions.setSilentFetch({ silentFetch: false }));
    }
  }, [index, text, speed]);

  return displayedText ? (
    <Markdown rehypePlugins={[rehypeRaw as any] as any}>
      {displayedText}
    </Markdown>
  ) : (
    <Loading loading={t("aiAssistant.wait")} />
  );
};

const ChatBubble = ({
  text,
  isQuestion,
  isTypingEffect = false,
  t,
}: {
  text: string;
  isQuestion: boolean;
  isTypingEffect?: boolean;
  t: any;
}) => (
  <ListItem
    sx={{
      justifyContent: isQuestion ? "flex-end" : "flex-start",
      paddingLeft: isQuestion ? "1.5rem" : 0,
      paddingRight: isQuestion ? 0 : "1.5rem",
    }}
  >
    <Box
      sx={{
        p: 1,
        borderRadius: 1,
        bgcolor: isQuestion ? "primary.main" : "grey.300",
        color: isQuestion ? "white" : "black",
        whiteSpace: "pre-line",
        maxWidth: "100%",
      }}
    >
      {isTypingEffect ? (
        <TypingEffect text={text} t={t} />
      ) : text ? (
        <Markdown rehypePlugins={[rehypeRaw as any] as any}>{text}</Markdown>
      ) : (
        <Loading loading={t("aiAssistant.wait")} />
      )}
    </Box>
  </ListItem>
);

const ChatItem = ({ message, idx, dataLength, silentFetch }: IProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (message && !message.answer) {
      dispatch(silentFetchAiRecommendations());
    }
  }, [dispatch, message]);

  return (
    <>
      <ChatBubble text={message.question} isQuestion={true} t={t} />
      <ChatBubble
        text={message.answer}
        isQuestion={false}
        isTypingEffect={silentFetch && idx === dataLength}
        t={t}
      />
    </>
  );
};
export default ChatItem;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { actions } from "./slice";
import { AppState } from "store";
import getAIRecommendation from "api/handlers/event/getAIRecommendation";
import sendAIMessage from "api/handlers/event/sendAIMessage";

export const getAiRecommendations = createAsyncThunk(
  "events/getAiRecommendations",
  async (
    {
      eventId,
    }: {
      eventId: number;
    },
    { dispatch, getState }
  ) => {
    dispatch(
      actions.setAiRecommendationLoading({ aiRecommendationLoading: true })
    );
    try {
      const data = await getAIRecommendation({
        id: eventId,
      });

      dispatch(
        actions.setAiRecommendations({
          aiRecommendation: data,
          aiRecommendationParams: {
            id: eventId,
          },
        })
      );
      dispatch(
        actions.setAiRecommendationLoading({ aiRecommendationLoading: false })
      );
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);

export const silentFetchAiRecommendations = createAsyncThunk(
  "events/silentFetchAiRecommendations",
  async (_, { dispatch, getState }) => {
    const { id } = (getState() as AppState).eventModal.aiRecommendationParams;
    try {
      const data = await getAIRecommendation({
        id,
      });

      dispatch(
        actions.setAiRecommendations({
          aiRecommendation: data,
          aiRecommendationParams: { id },
        })
      );
      dispatch(actions.setSilentFetch({ silentFetch: true }));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);

export const sendAiMessage = createAsyncThunk(
  "events/sendAiMessage",
  async (
    {
      eventId,
      text,
    }: {
      eventId: number;
      text: string;
    },
    { dispatch, getState }
  ) => {
    try {
      const res = await sendAIMessage({
        id: eventId,
        text,
      });

      dispatch(silentFetchAiRecommendations());

      dispatch(actions.setSilentFetch({ silentFetch: false }));
      return res;
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
);

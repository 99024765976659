import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { EActions, IMachineManualAction } from "./types";
import { AppState } from "store";
import { enqueueSnackbar } from "notistack";
import i18next from "i18next";
import getMachineManual from "api/handlers/machine/getMachineManual";
import addMachineManual from "api/handlers/machine/addMachineManual";
import removeMachineManual from "api/handlers/machine/removeMachineManual";
export const path = "machineDetail/machineManual";

const pairs = [
  [EActions.fetchMachineManualRequest],
  [EActions.fetchMachineManualSuccess, "manualsAttachment"],
  [EActions.fetchMachineManualFail, "error"],
  [EActions.setAttachmentParams, "attachmentParams"],
  [EActions.setReset],
];

const actions = prepareActions<IMachineManualAction, EActions>(pairs, path);

export default actions;

export const fetchMachineManuals =
  ({ id, page, ps }: { id: number; page?: number; ps?: number }) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const { manualsAttachment: currentAttachments, showMoreClickedTimesRef } =
      getState().machineDetail.machineManuals;

    dispatch(actions[EActions.fetchMachineManualRequest]());

    try {
      const machineManuals = await getMachineManual({
        id,
        page,
        ps,
      });

      dispatch(
        actions[EActions.setAttachmentParams]({
          id,
          page,
          ps,
        })
      );

      dispatch(
        actions[EActions.fetchMachineManualSuccess](
          showMoreClickedTimesRef.current
            ? {
                ...machineManuals,
                results: currentAttachments.results.concat(
                  machineManuals.results
                ),
              }
            : machineManuals
        )
      );
    } catch (error: any) {
      dispatch(actions[EActions.fetchMachineManualFail](error));
    }
  };

export const refetchMachineManualsSilent =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { id, page, ps } =
        getState().machineDetail.machineManuals.attachmentParams;
      const machineManuals = await getMachineManual({
        id,
        page,
        ps,
      });
      dispatch(actions[EActions.fetchMachineManualSuccess](machineManuals));
    } catch (error) {
      dispatch(actions[EActions.fetchMachineManualFail](error));
    }
  };

export const createMachineAttachment =
  ({ machine, name, data }: { machine: number; name: string; data: string }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    enqueueSnackbar(
      i18next.t("machine.detail.manualAttachment.createInProgress")
    );
    try {
      await addMachineManual({ machine, name, data });
      dispatch(refetchMachineManualsSilent());

      enqueueSnackbar(
        i18next.t("machine.detail.manualAttachment.createSuccess")
      );
    } catch (err: any) {
      if (err?.message === "invalid_attachment_parameter") {
        enqueueSnackbar(
          i18next.t("machine.detail.manualAttachment.invalidFile")
        );
      } else {
        enqueueSnackbar(
          i18next.t("machine.detail.manualAttachment.createError")
        );
      }
    }
  };

export const deleteMachineAttachment =
  (id: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await removeMachineManual(id);
      dispatch(refetchMachineManualsSilent());

      enqueueSnackbar(
        i18next.t("machine.detail.manualAttachment.deleteSuccess")
      );
    } catch (err: any) {
      enqueueSnackbar(i18next.t("machine.detail.manualAttachment.deleteError"));
    }
  };

import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import moment from "moment";

import { EActions, IActionTypes } from "./types";
import { AppState } from "store";
import { prepareActions } from "store/helpers";
import { ExportFormats } from "components/machine/detail/eventsExporter/types";
import { exportToSheet } from "components/machine/detail/eventsExporter/export";
import i18n from "localization";
import { enqueueSnackbar } from "notistack";
import {
  dateToDDMMYYYY,
  dateToHHMM,
  prepareMessage,
  getUsers,
} from "shared/helpers";
import getExportedEvents, {
  IEventsParams,
} from "api/handlers/event/getExportedEvents";
import urls from "shared/appUrls";

export const path = "machineDetail/eventsExporter";

const actionData = [
  [EActions.setModalOpen, "modalOpen"],
  [EActions.reset],
  [EActions.setExportStart],
  [EActions.setExportEnd],
];

const actions = prepareActions<IActionTypes, EActions>(actionData, path);

export const exportEvents =
  (format: ExportFormats, filters: IEventsParams) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) => {
    dispatch(actions[EActions.setExportStart]());
    const from = filters.from;
    const to = filters.to;
    try {
      const events = await getExportedEvents(filters);
      const bodyJson = events.map((item: any) => {
        const date = new Date(item.created_at);
        const lastSent =
          item.mail_last_sent_on === null
            ? null
            : new Date(item.mail_last_sent_on);
        return {
          [i18n.t("eventsExporter.sheet.companyName")]: item.company_name,
          [i18n.t("eventsExporter.sheet.companyId")]: item.company,
          [i18n.t("eventsExporter.sheet.projectName")]: item.project_name,
          [i18n.t("eventsExporter.sheet.projectId")]: item.project,
          [i18n.t("eventsExporter.sheet.facilityName")]: item.facility_name,
          [i18n.t("eventsExporter.sheet.facilityId")]: item.facility,
          [i18n.t("eventsExporter.sheet.machineName")]: item.machine_name,
          [i18n.t("eventsExporter.sheet.machineId")]: item.machine,
          [i18n.t("eventsExporter.sheet.placementNames")]: item.placement_names
            ? item.placement_names.join(", ")
            : "",
          [i18n.t("eventsExporter.sheet.placementIds")]:
            item.placements !== null
              ? item.placements.map((i: number) => `${i}`).join(", ")
              : "",
          [i18n.t("eventsExporter.sheet.triggeredAtDateTime")]: moment(
            date
          ).format("YYYY-MM-DD HH:mm:ss"),
          [i18n.t("eventsExporter.sheet.triggeredAtDateTimeUTC")]: moment(date)
            .tz("UTC")
            .format("YYYY-MM-DD HH:mm:ss"),
          [i18n.t("eventsExporter.sheet.eventId")]: item.id,
          [i18n.t("eventsExporter.sheet.eventType")]: i18n.t(
            `eventTypeNames.${item.type}`
          ),
          [i18n.t("eventsExporter.sheet.message")]: prepareMessage(
            item
          ).replace(/(<([^>]+)>)/gi, ""),
          [i18n.t("eventsExporter.sheet.status")]:
            (item.type === "anomaly" || item.type === "anomaly_warning") &&
            item.status
              ? i18n.t(`alert_status.${item.status}`)
              : "",
          [i18n.t("eventsExporter.sheet.isCritical")]:
            (item.type === "anomaly" || item.type === "anomaly_warning") &&
            item.status &&
            (item.status === "customer_informed" ||
              (item.status === "resolved" &&
                item.alert_severity &&
                item.alert_severity !== "no_failure"))
              ? item.is_critical === true
                ? i18n.t("eventsExporter.sheet.isCriticalYes")
                : item.is_critical === false
                  ? i18n.t("eventsExporter.sheet.isCriticalNo")
                  : ""
              : "",
          [i18n.t("eventsExporter.sheet.severity")]: i18n.t(
            item.alert_severity ? `severities.${item.alert_severity}` : ""
          ),
          [i18n.t("eventsExporter.sheet.masterAlert")]: item.master_alert || "",
          [i18n.t("eventsExporter.sheet.note")]: item.note,
          [i18n.t("eventsExporter.sheet.linkToEvent")]: `https://${
            window.location.hostname
          }${urls.machines.detailEvents(item.machine)}?id=${item.id}`,
          [i18n.t("eventsExporter.sheet.who")]: getUsers(
            item.created_by_user_email,
            item.modified_by_user_email
          ),
          [i18n.t("eventsExporter.sheet.mailLastSentOn")]:
            lastSent === null
              ? ""
              : moment(lastSent).format("YYYY-MM-DD HH:mm:ss"),
          [i18n.t("eventsExporter.sheet.mailLastSentOnUTC")]:
            lastSent === null
              ? ""
              : moment(lastSent).tz("UTC").format("YYYY-MM-DD HH:mm:ss"),
          [i18n.t("eventsExporter.sheet.presumedCauses")]: item.presumed_causes
            ? item.presumed_causes
            : "",
          [i18n.t("eventsExporter.sheet.recommendedAction")]:
            item.recommended_action ? item.recommended_action : "",
          [i18n.t("eventsExporter.sheet.anomalyTrend")]: item.anomaly_trend
            ? item.anomaly_trend
            : "",
          [i18n.t("eventsExporter.sheet.diagDescription")]:
            item.diag_description ? item.diag_description : "",
        };
      });

      if (from) {
        const fromName = `${dateToDDMMYYYY(from)}_${dateToHHMM(from)}`;
        const toName =
          to !== null ? `__${dateToDDMMYYYY(to!)}_${dateToHHMM(to!)}` : "";

        await exportToSheet(
          bodyJson,
          `event_export_${fromName}${toName}`,
          format
        );
        enqueueSnackbar(i18n.t("eventsExporter.success"));
        dispatch(actions[EActions.reset]());
      }
    } catch (error) {
      enqueueSnackbar(i18n.t("eventsExporter.error"));
    }
  };

export default actions;

import { Box, Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SingleActionModal from "components/singleActionModal";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Heading4 from "components/typography/heading/heading4";
import Paragraph from "components/typography/paragraph";
import LinkComponent from "components/typography/link";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { createMachineAttachment } from "store/machineDetail/machineManuals/actions";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme: any) => ({
  dropContainer: {
    position: "relative",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));

interface IProps {
  open: boolean;
  setOpen: (event: any) => void;
  machine: number;
}

const UploadManualAttachment = ({ open, setOpen, machine }: IProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();

  const [attachments, setAttachments] = useState<any>([]);

  const classes = useStyles();
  const onCancel = useCallback(() => {
    setOpen(false);
  }, []);

  const onDragCapture = useCallback(async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const fileReader = new FileReader();
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    const name = file?.name;

    if (file.size > 10485760) {
      enqueueSnackbar(t("machine.detail.uploadManualAttachment.tooLarge"));
      return;
    }

    fileReader.readAsDataURL(file);
    e.target.value = null;
    fileReader.onload = async (loadEvent: any) => {
      const target = loadEvent!.target!;
      const data = target.result!.toString().split(",")[1];
      setAttachments((prevAttachments: any) => [
        ...prevAttachments,
        {
          name,
          data,
          id: uuidv4(),
        },
      ]);
    };
  }, []);

  const onFinish = useCallback(() => {
    setOpen(false);
    attachments.forEach((item: any) => {
      dispatch(
        createMachineAttachment({
          machine,
          name: item.name,
          data: item.data,
        })
      );
    });
  }, [dispatch, machine, attachments]);

  const handleRemoveAttachment = (id: number) => {
    setAttachments((prevAttachments: any) =>
      prevAttachments.filter((item: any) => item.id !== id)
    );
  };

  return (
    <>
      <SingleActionModal
        open={open}
        modalTitle={t("machine.detail.uploadManualAttachment.title")}
        finishButtonLabel={t("machine.detail.uploadManualAttachment.confirm")}
        closeModal={onCancel}
        handleFinish={onFinish}
        submitDisabled={!attachments.length}
      >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          className={classes.dropContainer}
        >
          <Grid item xs={12} sm={8} md={6}>
            <Box textAlign="center" py={2}>
              <Box mb={2}>
                <Heading4>
                  <label htmlFor="upload-pdf">
                    <input
                      style={{ display: "none" }}
                      id="upload-pdf"
                      name="upload-pdf"
                      type="file"
                      accept=".pdf"
                      onChange={onDragCapture}
                    />
                    <LinkComponent>
                      {t("manualAttachmentUploader.selectPdf")}
                    </LinkComponent>
                  </label>
                </Heading4>
              </Box>
              <Paragraph italic>
                {t("manualAttachmentUploader.allowedFormats")}
              </Paragraph>
              <Paragraph italic>
                {t("audioUploader.maxFileSize", {
                  mb: 10,
                })}
              </Paragraph>
            </Box>
          </Grid>
          {attachments.length ? (
            <Box>
              {attachments.map((item: any) => {
                return (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <span>{item.name}</span>
                    <Button onClick={() => handleRemoveAttachment(item.id)}>
                      <CloseIcon />
                    </Button>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      </SingleActionModal>
    </>
  );
};

export default UploadManualAttachment;

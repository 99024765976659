import * as FileSaver from "file-saver";
import { ExportFormats } from "./types";
import i18n from "localization";

export const exportToSheet = async (
  bodyJson: any,
  fileName: string,
  format: ExportFormats
) => {
  const XLSX = await import("xlsx");
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = format === ExportFormats.CSV ? ".csv" : ".xls";
  const workSheet = XLSX.utils.json_to_sheet(bodyJson);
  if (format === ExportFormats.XLS) {
    for (var i = 0; i < bodyJson.length; ++i) {
      workSheet[XLSX.utils.encode_cell({ c: 20, r: i + 1 })].l = {
        Target: bodyJson[i][i18n.t("eventsExporter.sheet.linkToEvent")],
      };
    }
  }
  const wb = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, {
    bookType: format === ExportFormats.CSV ? "csv" : "xls",
    bookSST: format === ExportFormats.CSV ? undefined : true,
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

import { Box } from "@mui/material";
import DataChunk from "components/eventSounds/components";
import { CellProps } from "react-table";

const ChunkList = <T extends Object>(props: CellProps<T>) => {
  const {
    cell: {
      value: { chunks, setIsPlaying, label, setPlayingAll, volume },
    },
  } = props;

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gridGap: 2 }}>
      {chunks.map((chunk: any) => (
        <DataChunk
          key={`${chunk.id}`}
          buffer={chunk}
          setIsPlaying={setIsPlaying({ id: chunk.id, key: label })}
          isPlaying={chunk.isPlaying}
          setPlayingAll={setPlayingAll}
          volume={volume}
        />
      ))}
    </Box>
  );
};

export default ChunkList;

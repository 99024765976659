import { memo, useCallback } from "react";
import FailuresSelect from "components/failuresSelect";
import { useDispatch } from "react-redux";
import { actions } from "store/eventModal/slice";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import IconButton from "components/iconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import isEqual from "react-fast-compare";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: { width: "100%" },
    wrapper: {
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "start",
      },
    },
    content: {
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        justifyContent: "inherit",
        gap: "12px",
      },
    },
    contentItem: {
      [theme.breakpoints.up("sm")]: {
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
  };
});

type TFailuresProps = {
  failures: (number | null)[];
  other_failures: (string | null)[];
  notes: (string | null)[];
  placementType: number;
  placement: number;
  index: number;
};

const Failure = memo(
  ({
    failures,
    other_failures,
    placementType,
    placement,
    notes,
    index,
  }: TFailuresProps) => {
    const dispatch: any = useDispatch();
    const styles = useStyles();
    const { t } = useTranslation();

    const onSetFailure = useCallback(
      (failure: any, placement: any, index: any) => {
        dispatch(
          actions.setFailure({
            placement,
            failure: failure > 0 ? failure : null,
            index,
          })
        );
      },
      [dispatch]
    );

    return (
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        style={{ marginTop: "0.5rem" }}
        spacing={2}
        className={styles.wrapper}
      >
        <IconButton
          size="small"
          onClick={() => {
            dispatch(
              actions.removeFailure({
                placement,
                idIndex: index,
              })
            );
          }}
        >
          <DeleteIcon />
        </IconButton>
        <Grid item xs={10} alignItems="center" className={styles.content}>
          <Box display="flex" className={styles.contentItem}>
            <FailuresSelect
              value={failures[index] ?? 0}
              placementType={Number(placementType)}
              onChange={(failure: any) => {
                onSetFailure(Number(failure), placement, index);
              }}
            />
          </Box>
          {failures[index] === null ? (
            <Box mt={1} className={styles.root}>
              <TextField
                className={styles.root}
                label={t("eventModal.failures.other_failure")}
                variant="outlined"
                onChange={(e: any) => {
                  dispatch(
                    actions.setOtherFailure({
                      placement: placement,
                      other_failure: e.target.value,
                      index,
                    })
                  );
                }}
                value={other_failures[index] ?? ""}
              />
            </Box>
          ) : null}
          <Box mt={1} className={styles.root}>
            <TextField
              className={styles.root}
              label={t("eventModal.failures.note")}
              variant="outlined"
              onChange={(e: any) => {
                dispatch(
                  actions.setNote({
                    placement: placement,
                    note: e.target.value,
                    index,
                  })
                );
              }}
              value={notes[index] ?? ""}
            />
          </Box>
        </Grid>
      </Grid>
    );
  },
  isEqual
);

export default Failure;

import { ThemedSlider } from "components/dataLabeling/player/Slider";

const MIN_DB = 0;
const MAX_DB = 40;
const STEP_DB = 5;
const VolumeSlider = ({ volume, setVolume }: any) => {
  return (
    <div
      style={{
        width: "8rem",
        marginLeft: "1rem",
        marginRight: "0.5rem",
        textAlign: "center",
        lineHeight: 0.5,
        marginBottom: "0.75rem",
      }}
    >
      <ThemedSlider
        value={volume}
        step={STEP_DB}
        min={MIN_DB}
        max={MAX_DB}
        onChange={(e, volume) => {
          setVolume(volume);
        }}
        marks={true}
      />
      + {volume} dB
    </div>
  );
};

export default VolumeSlider;
